<script lang="ts">import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from "@crownframework/svelte-error-boundary/src/index";
import { Swiper } from "swiper";
import "swiper/swiper-bundle.css";
import _ from "lodash";
import { afterUpdate, onMount } from "svelte";
import { afterUpdateDebounce } from "../../../js/utilities";
import { getContext } from "svelte";
import { CardsPageContextKey } from "./context";
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
import "./actions";
export let parentSequenceId = "";
export let layout;
export let contentAvailable = true;
export let updater;
export const messageHandler = (action) => {
    switch (action === null || action === void 0 ? void 0 : action.effect) {
        case "carousel-left": {
            onPrev();
            break;
        }
        case "carousel-right": {
            onNext();
            break;
        }
    }
};
const pageContext = getContext(CardsPageContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
let swiperComponent;
let childContentAvailable = {};
let childContentActive = [];
let hasScrolledToActive = false;
let whenFirstContentAvailable;
let backgroundFilter;
let outlineMode;
let swiperInstance;
$: outlineMode = $emmEnabled && !$emmPreview;
$: {
    contentAvailable = Object.values(childContentAvailable).some((item) => item);
}
$: allowScrollToActive = !_.isNil(layout.scrollToActive)
    ? layout.scrollToActive
    : true;
$: maxElements = (layout === null || layout === void 0 ? void 0 : layout.maxElements) || Number.MAX_SAFE_INTEGER;
$: originalSwipeEffect = (layout.swipeEffect || "").toLowerCase();
$: swipeEffect = (() => {
    const _swipeEffect = (originalSwipeEffect || "slide").toLowerCase();
    return _swipeEffect === "magnify" ? "coverflow" : _swipeEffect;
})();
afterUpdate(afterUpdateDebounce(() => {
    // const swiperInstance = swiperComponent?.swiper;
    if (!whenFirstContentAvailable && contentAvailable) {
        whenFirstContentAvailable = Date.now();
    }
    if (swiperInstance && swiperInstance.$el) {
        swiperInstance.slideTo(0);
        if (allowScrollToActive &&
            !hasScrolledToActive &&
            contentAvailable &&
            Date.now() - whenFirstContentAvailable < 1000) {
            const firstContentActiveIndex = childContentActive.indexOf(true);
            if (firstContentActiveIndex >= 0) {
                swiperInstance.slideTo(firstContentActiveIndex, 0);
                hasScrolledToActive = true;
            }
        }
        if (layout.swiperNavigation) {
            swiperInstance.on("slideChange", controlNavigationButton);
        }
        swiperInstance.update();
    }
}));
$: onPrev = () => {
    swiperInstance.slidePrev(layout.scrollSpeed || 400);
};
$: onNext = () => {
    swiperInstance.slideNext(layout.scrollSpeed || 400);
};
let btnPrev, btnNext;
const controlNavigationButton = () => {
    if (swiperInstance) {
        // const swiperInstance = swiperComponent?.swiper;
        const perGroup = layout.maxColumns > 1
            ? Math.ceil(layout.elements.length / layout.maxColumns)
            : layout.elements.length;
        const progress = swiperInstance.progress;
        if (perGroup === layout.elements.length) {
            btnPrev === null || btnPrev === void 0 ? void 0 : btnPrev.classList.remove("hidden");
            btnNext === null || btnNext === void 0 ? void 0 : btnNext.classList.remove("hidden");
        }
        if (progress === 0) {
            btnPrev === null || btnPrev === void 0 ? void 0 : btnPrev.classList.add("hidden");
            btnNext === null || btnNext === void 0 ? void 0 : btnNext.classList.remove("hidden");
        }
        else if (progress >= 0.96 || (swiperInstance === null || swiperInstance === void 0 ? void 0 : swiperInstance.isEnd)) {
            btnNext === null || btnNext === void 0 ? void 0 : btnNext.classList.add("hidden");
            btnPrev === null || btnPrev === void 0 ? void 0 : btnPrev.classList.remove("hidden");
        }
        else {
            btnPrev === null || btnPrev === void 0 ? void 0 : btnPrev.classList.remove("hidden");
            btnNext === null || btnNext === void 0 ? void 0 : btnNext.classList.remove("hidden");
        }
    }
};
$: backgroundFilter = layout.backgroundFilter;
let paginationOffset = layout.paginationOffset || "0px";
let paginationBackgroundColor = layout.paginationBackgroundColor || "var(--yc-theme-color-background)";
let paginationActiveColor = layout.paginationActiveColor || "var(--yc-theme-color-active)";
$: padding = (layout === null || layout === void 0 ? void 0 : layout.swiperPadding) || {
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
};
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
function generateUid() {
    return "uid-" + Date.now() + "-" + Math.floor(Math.random() * 10000);
}
let uid = generateUid();
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    forceHorizontalScrollbar: true,
    updater,
};
onMount(() => {
    swiperInstance = new Swiper(`.swiper${uid}`, {
        navigation: { nextEl: ".btn-next", prevEl: ".btn-prev" },
        pagination: layout.swiperPagination ? { clickable: true } : false,
        speed: layout.scrollSpeed || 400,
        slidesPerView: layout.maxColumns || "auto",
        spaceBetween: layout.gapPixels || 10,
        simulateTouch: false,
        effect: swipeEffect,
        freeMode: !_.isNil(layout.slideSnap) && !layout.slideSnap,
        observer: true,
        observeSlideChildren: false,
        centerInsufficientSlides: false,
        slidesPerGroup: layout.maxColumns || 1,
        grabCursor: true,
        coverflowEffect: originalSwipeEffect === "magnify"
            ? {
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 4,
                slideShadows: false,
            }
            : {},
        autoplay: layout.autoplay,
        loop: !!layout.loop,
        lazyPreloadPrevNext: layout.preloadSlideCount || 3,
        loopAdditionalSlides: layout.loopExtraSlides || 3,
    });
    swiperInstance.on("slideChange", controlNavigationButton);
    controlNavigationButton();
});
</script>

<Boundary>
  {#if outlineMode}
    <CardsContainerWrapper
      style="width: 100%; overflow-x: scroll; display: grid; grid-auto-flow: column; grid-auto-columns: 200px; grid-column-gap: 10px; justify-content: safe center;"
      {editorOptions}
    >
      {#each layout.elements as element, i (element.id)}
        <CardsElement
          {parentSequenceId}
          sequenceId={generateSequenceId("ELT", parentSequenceId, i)}
          {element}
          height="100%"
          bind:contentAvailable={childContentAvailable[element.id]}
          bind:contentActive={childContentActive[i]}
          updater={elementUpdater.bind(null, i)}
          on:contentLoaded
        />
      {/each}
    </CardsContainerWrapper>
  {:else}
    <div
      class="swiper-layout"
      class:pagination={!!layout.swiperPagination}
      style="--yinzcam-cards-array-swiper-layout-slide-width:{layout.maxColumns
        ? 'auto'
        : layout.slideWidth || '40%'};
      --pagination-active-color:{paginationActiveColor};
      --pagination-background-color: {paginationBackgroundColor};
      --pagination-offset: {paginationOffset};
      mask-image: {backgroundFilter};
      -webkit-mask-image: {backgroundFilter};
      --padding-top: {typeof padding.top === 'string'
        ? padding.top
        : padding.top + 'px'};
      --padding-right: {typeof padding.right === 'string'
        ? padding.right
        : padding.right + 'px'};
      --padding-bottom: {typeof padding.bottom === 'string'
        ? padding.bottom
        : padding.bottom + 'px'};
      --padding-left: {typeof padding.left === 'string'
        ? padding.left
        : padding.left + 'px'};
      min-height: {layout.minHeight};
      max-height: {layout.maxHeight};
      --yinzcam-cards-array-swiper-layout-overflow: {layout.overflow ||
        'visible'};
      width: {layout.width || 'auto'};
      height: {layout.height || 'auto'};
    "
    >
      <div class="swiper-container" bind:this={swiperComponent}>
        <div class={`swiper${uid}`}>
          <div class="swiper-wrapper">
            {#each layout.elements as element, i (element.id)}
              <div class="swiper-slide" class:display-none={i >= maxElements ||
                    childContentAvailable[element.id] === false}>
                <div
                  class="middle-class">
                  <CardsElement
                    {parentSequenceId}
                    sequenceId={generateSequenceId("ELT", parentSequenceId, i)}
                    {element}
                    height="100%"
                    bind:contentAvailable={childContentAvailable[element.id]}
                    bind:contentActive={childContentActive[i]}
                    updater={elementUpdater.bind(null, i)}
                    on:contentLoaded
                  />
                </div>
              </div>
            {/each}
          </div>
          {#if !!layout.swiperNavigation}
            <div
              class="btn-navigation"
              class:show-only-hover={layout.swiperNavigationOnHover}
            >
              <div
                class="btn btn-prev"
                on:click={onPrev}
                bind:this={btnPrev}
                style="margin-left: {layout.swiperNavigationOffset || '0px'}"
              >
                <i class="f7-icons">chevron_left</i>
              </div>
              <div
                class="btn btn-next"
                on:click={onNext}
                bind:this={btnNext}
                style="margin-right: {layout.swiperNavigationOffset || '0px'}"
              >
                <i class="f7-icons">chevron_right</i>
              </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/if}
</Boundary>

<style>
  .swiper-layout {
    position: relative;
  }

  .btn-navigation {
    position: absolute;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: calc(
      (50% - 28px - var(--padding-bottom) / 2 - var(--padding-top) / 2)
    );
    display: flex;
    justify-content: space-between;
    z-index: 2;
  }

  .btn-navigation .btn {
    position: absolute;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    border: none;
    background: #b0b8bf;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn-prev{
    left: 20px;
    right: auto;
  }

  .btn-next{
    right: 0;
    left: auto;
  }

  .btn-navigation .btn:hover {
    background: #818b94;
  }

  .btn-navigation .btn i {
    font-size: 28px;
  }

  .swiper-layout :global(.hidden) {
    visibility: hidden !important;
  }

  .swiper-layout :global(.swiper-container) {
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom)
      var(--padding-left);
    overflow: var(--yinzcam-cards-array-swiper-layout-overflow, clip);
    overflow-clip-margin: 10px;
  }

  .swiper-layout :global(.swiper-slide) {
    width: var(--yinzcam-cards-array-swiper-layout-slide-width);
    height: initial !important;
  }

  @media (max-width: 768px) {
    .swiper-layout :global(.swiper-slide) {
      width: var(--yinzcam-cards-array-swiper-layout-slide-width) !important;
      max-width: 100vw;
      height: initial !important;
    }
  }

  .swiper-layout :global(.swiper-pagination.swiper-pagination-bullets) {
    bottom: var(--pagination-offset) !important;
  }

  .swiper-layout :global(.swiper-pagination .swiper-pagination-bullet) {
    background: var(--pagination-background-color);
  }

  .swiper-layout :global(.swiper-pagination .swiper-pagination-bullet-active) {
    background: var(--pagination-active-color);
  }

  .show-only-hover.btn-navigation {
    visibility: hidden;
  }

  .swiper-layout:hover .show-only-hover.btn-navigation {
    visibility: visible;
  }

  .middle-class{
    height: 100%;
  }</style>
