<script context="module" lang="ts">var _a, _b;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b;
import { get, merge, uniqueId } from "lodash";
import { DateTime } from "luxon";
import { BaseAtom, getSEOManager } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import "js/seo";
import { onDestroy } from "svelte";
export let container;
export let overlayPadding;
export let backgroundImage;
export let homePlayerGoals = undefined;
export let awayPlayerGoals = undefined;
export let homeTeamDetails = undefined;
export let awayTeamDetails = undefined;
export let imgLeagueIcon;
export let imgLiveIcon;
export let txtLive;
export let txtMinute;
export let txtHomeScore;
export let txtAwayScore;
export let txtVenueName;
export let matchDateTime;
export let matchStatus;
export let txtMatchDate;
export let txtMatchTime;
export let txtFullTime;
export let isMatchTimeBoxRequired;
export let setPageMetadata = undefined;
let atomWidthRem;
const formatDateTime = (dateString) => {
    var _a;
    const dt = DateTime.fromISO(dateString);
    const convertedDateTime = (_a = dt
        .toLocaleString(Object.assign(Object.assign(Object.assign({}, DateTime.DATE_FULL), { weekday: "long" }), DateTime.TIME_24_SIMPLE))) === null || _a === void 0 ? void 0 : _a.split(" ");
    return convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.map((string) => string.trim().replace(",", ""));
};
$: isMobile = atomWidthRem <= 47;
$: isTablet = atomWidthRem <= 70 && atomWidthRem > 47;
$: txtPlayerNameMultiplier = isMobile ? "0.60" : isTablet ? "0.8" : "1.00";
$: txtTeamNameMultiplier = isMobile ? "0.50" : isTablet ? "0.67" : "1.00";
$: txtLiveMultiplier = isMobile ? "0.70" : "1.00";
$: txtScoreMultiplier = isMobile ? "0.35" : isTablet ? "0.6" : "1.00";
$: txtStadiumMultiplier = isMobile ? "0.58" : isTablet ? "0.83" : "1.00";
$: imgTeamIconMultiplier = isMobile ? "0.52" : isTablet ? "0.62" : "1.00";
$: imgLiveIconMultiplier = isMobile || isTablet ? "1.00" : "1.00";
$: [day, month, date, year, , time] = formatDateTime(matchDateTime);
const seoManager = getSEOManager();
const seoConfigKey = uniqueId();
$: if (setPageMetadata && ((_a = homeTeamDetails === null || homeTeamDetails === void 0 ? void 0 : homeTeamDetails.name) === null || _a === void 0 ? void 0 : _a.text) && ((_b = awayTeamDetails === null || awayTeamDetails === void 0 ? void 0 : awayTeamDetails.name) === null || _b === void 0 ? void 0 : _b.text)) {
    /*
    seoManager.registerSEOConfiguration({
        key: seoConfigKey,
        priority: JanusSEOPriority.Card,
        isPopup: false,
        title: homeTeamDetails.name.text + ' v ' + awayTeamDetails.name.text,
    });
    */
}
onDestroy(() => {
    seoManager.unregisterSEOConfiguration(seoConfigKey);
});
</script>

<BaseAtom
    _isotope="MCHeaderAtom"
    _cssVariablePrefix="match-centre-header"
    {...container}
    {overlayPadding}
    bind:_clientWidthRem={atomWidthRem}
>
    <div class="main-container">
        <div class="background-image">
            <ImageParticle {...backgroundImage} _block={true} />
        </div>
        <div
            class="overlay-area"
            class:tablet-width={isTablet}
            class:mobile-width={isMobile}
        >
            <div class="league-icon">
                <ImageParticle
                    {...imgLeagueIcon}
                    {...merge({}, imgLeagueIcon, {
                        container: {
                            height: `calc(${get(imgLeagueIcon, "container.height")}*${imgTeamIconMultiplier})`,
                            width: `calc(${get(imgLeagueIcon, "container.width")}*${imgTeamIconMultiplier})`,
                        },
                    })}
                    _block={true}
                />
            </div>
            <div class="main-content">
                <div class="players-left-card">
                    {#each homePlayerGoals || [] as homeGoal}
                        <div class="home-goals-box">
                            <div class="name-minute">
                                <TextParticle
                                    {...homeGoal.name}
                                    size={`calc(${get(homeGoal, "name.size")} * ${txtPlayerNameMultiplier})`}
                                />
                                <TextParticle
                                    {...homeGoal.goalMinute}
                                    size={`calc(${get(homeGoal, "goalMinute.size")} * ${txtPlayerNameMultiplier})`}
                                />
                            </div>
                            <ImageParticle {...homeGoal.icon} _block={true} />
                        </div>
                    {/each}
                </div>
                <div class="match-centre-card">
                    <div class="home-team-details">
                        <ImageParticle
                            {...merge({}, get(homeTeamDetails, "icon"), {
                                container: {
                                    height: `calc(${get(homeTeamDetails, "icon.container.height")}*${imgTeamIconMultiplier})`,
                                    width: `calc(${get(homeTeamDetails, "icon.container.width")}*${imgTeamIconMultiplier})`,
                                },
                            })}
                            _block={true}
                        />
                        <TextParticle
                            {...homeTeamDetails && homeTeamDetails.name}
                            size={`calc(${get(homeTeamDetails, "name.size")} * ${txtTeamNameMultiplier})`}
                        />
                    </div>
                    <div class="match-content">
                        {#if matchStatus === "live"}
                            <div class="live-content-box">
                                <div class="minute-score-venue">
                                    <div class="live-minute">
                                        <ImageParticle
                                            _block={true}
                                            {...merge({}, imgLiveIcon, {
                                                container: {
                                                    height: `calc(${get(imgLiveIcon, "container.height")}*${imgLiveIconMultiplier})`,
                                                    width: `calc(${get(imgLiveIcon, "container.width")}*${imgLiveIconMultiplier})`,
                                                },
                                            })}
                                        />
                                        <TextParticle
                                            {...txtLive}
                                            _block={true}
                                            size={`calc(${get(txtLive, "size")}*${txtLiveMultiplier})`}
                                        />
                                        <TextParticle
                                            {...txtMinute}
                                            _block={true}
                                            size={`calc(${get(txtMinute, "size")}*${txtLiveMultiplier})`}
                                        />
                                    </div>
                                    <div class="score-box">
                                        <TextParticle
                                            {...txtHomeScore}
                                            size={`calc(${get(txtHomeScore, "size")} * ${txtScoreMultiplier})`}
                                        />
                                        <span>-</span>
                                        <TextParticle
                                            {...txtAwayScore}
                                            size={`calc(${get(txtAwayScore, "size")} * ${txtScoreMultiplier})`}
                                        />
                                    </div>
                                    <div class="venue-name">
                                        <TextParticle
                                            {...txtVenueName}
                                            size={`calc(${get(txtVenueName, "size")}*${txtStadiumMultiplier})`}
                                        />
                                    </div>
                                </div>
                            </div>
                        {/if}
                        {#if matchStatus === "post"}
                            <div class="post-content-box">
                                <div class="match-date">
                                    <TextParticle
                                        {...txtMatchDate}
                                        text={`${day} ${month.slice(0, 3)} ${date}, ${year}`}
                                    />
                                </div>
                                <div class="score-box">
                                    <TextParticle
                                        {...txtHomeScore}
                                        size={`calc(${get(txtHomeScore, "size")} * ${txtScoreMultiplier})`}
                                    />
                                    <span>-</span>
                                    <TextParticle
                                        {...txtAwayScore}
                                        size={`calc(${get(txtAwayScore, "size")} * ${txtScoreMultiplier})`}
                                    />
                                </div>
                                <div class="full-time-text">
                                    <TextParticle
                                        {...txtFullTime}
                                        text="FULL TIME"
                                    />
                                </div>
                                <div class="venue-name">
                                    <TextParticle
                                        {...txtVenueName}
                                        size={`calc(${get(txtVenueName, "size")}*${txtStadiumMultiplier})`}
                                    />
                                </div>
                            </div>
                        {/if}
                        {#if matchStatus === "pre"}
                            <div class="pre-content-box">
                                <div class="match-date">
                                    <TextParticle
                                        {...txtMatchDate}
                                        text={`${day} ${month} ${date}, ${year}`}
                                    />
                                </div>
                                <div
                                    class="match-time-outer"
                                    class:border-box={isMatchTimeBoxRequired}
                                >
                                    <div class="match-time">
                                        <TextParticle
                                            {...txtMatchTime}
                                            text={time}
                                        />
                                    </div>
                                </div>
                                <div class="venue-name">
                                    <TextParticle
                                        {...txtVenueName}
                                        size={`calc(${get(txtVenueName, "size")}*${txtStadiumMultiplier})`}
                                    />
                                </div>
                            </div>
                        {/if}
                    </div>

                    <div class="away-team-details">
                        <ImageParticle
                            {...merge({}, get(awayTeamDetails, "icon"), {
                                container: {
                                    height: `calc(${get(awayTeamDetails, "icon.container.height")}*${imgTeamIconMultiplier})`,
                                    width: `calc(${get(awayTeamDetails, "icon.container.width")}*${imgTeamIconMultiplier})`,
                                },
                            })}
                            _block={true}
                        />
                        <TextParticle
                            {...awayTeamDetails && awayTeamDetails.name}
                            size={`calc(${get(homeTeamDetails, "name.size")} * ${txtTeamNameMultiplier})`}
                        />
                    </div>
                </div>
                <div class="players-right-card">
                    {#each awayPlayerGoals || [] as awayGoal}
                        <div class="away-goals-box">
                            <ImageParticle {...awayGoal.icon} _block={true} />
                            <div class="name-minute">
                                <TextParticle
                                    {...awayGoal.name}
                                    size={`calc(${get(awayGoal, "name.size")} * ${txtPlayerNameMultiplier})`}
                                />
                                <TextParticle
                                    {...awayGoal.goalMinute}
                                    size={`calc(${get(awayGoal, "goalMinute.size")} * ${txtPlayerNameMultiplier})`}
                                />
                            </div>
                        </div>
                    {/each}
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: grid;
        width: 100%;
    }

    .background-image {
        grid-area: 1/1;
    }
    .overlay-area {
        width: 100%;
        grid-area: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: var(--match-centre-header-overlay-padding, unset);
    }
    .main-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0;
        gap: 1rem;
        width: 100%;
    }
    .players-left-card,
    .players-right-card {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1 1;
    }
    .players-left-card .name-minute, .players-right-card .name-minute {
            /* width: 90%; */
            gap: 0.5rem;
        }
    .players-left-card {
        align-items: flex-end;
    }
    .home-goals-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }
    .home-goals-box .name-minute {
            text-align: right;
        }
    .away-goals-box {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    .match-centre-card {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex: 1 0 max-content;
    }
    .home-team-details,
    .away-team-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 0 1;
    }
    .live-content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem 0;
        gap: 1.5rem;
    }
    .league-icon {
        text-align: center;
        padding-top: 0.5rem;
    }
    .minute-score-venue {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
    }
    .live-minute {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.25rem;
    }
    .score-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .score-box > span {
        color: var(--theme-mode-foreground-color, #000);
        text-align: center;
        font-size: 2.35rem;
        font-weight: 700;
    }
    .venue-name {
        text-align: center;
        flex-wrap: wrap;
    }
    .overlay-area.tablet-width .main-content, .overlay-area.mobile-width .main-content {
            flex-wrap: wrap;
        }
    .overlay-area.tablet-width .main-content .players-left-card,
            .overlay-area.mobile-width .main-content .players-left-card,
            .overlay-area.tablet-width .main-content .players-right-card,
            .overlay-area.mobile-width .main-content .players-right-card {
                /* flex-basis: unset; */
            }
    .overlay-area.tablet-width > .main-content > .match-centre-card,
    .overlay-area.mobile-width > .main-content > .match-centre-card {
        order: -1;
        flex: 1 0 100%;
    }

    .overlay-area.tablet-width .main-content {
            padding: 0;
        }

    .overlay-area.tablet-width .main-content .match-centre-card {
                justify-content: center;
                gap: 2rem;
            }

    .overlay-area.mobile-width .league-icon {
            padding-top: 0.5rem;
        }

    .overlay-area.mobile-width .main-content {
            gap: 0.5rem;
            padding: 0 0 1rem 0;
        }

    .overlay-area.mobile-width .main-content .match-centre-card {
                align-items: center;
            }

    .overlay-area.mobile-width .main-content .match-centre-card .match-content .live-content-box {
                        gap: 0.75rem;
                    }

    .overlay-area.mobile-width .main-content .match-centre-card .match-content .live-content-box .minute-score-venue {
                            gap: 0;
                        }

    .overlay-area.mobile-width .main-content .match-centre-card .match-content .live-content-box .minute-score-venue .live-minute {
                                justify-content: center;
                                gap: 0;
                            }

    .overlay-area.mobile-width .main-content .match-centre-card .match-content .live-content-box .minute-score-venue .score-box {
                                justify-content: center;
                                gap: 1rem;
                            }

    .overlay-area.mobile-width .main-content .match-centre-card .match-content .pre-content-box,
                    .overlay-area.mobile-width .main-content .match-centre-card .match-content .post-content-box {
                        gap: 0.5rem;
                    }
    .pre-content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0;
        gap: 1.5rem;
    }
    .pre-content-box .match-time-outer {
            padding: 0.5rem;
        }
    .pre-content-box .match-time-outer.border-box {
            border: 1px solid var(--theme-mode-accent-color, #fff);
            border-radius: 0.5rem;
        }

    .post-content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0;
        gap: 0.5rem;
    }</style>
