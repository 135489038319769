<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { merge, get } from "lodash";
import { formatDateTimeCards } from "components/cards/utilities";
import "luxon";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
const hovering = writable(false);
export let container;
export let overlayPadding;
export let containerBackgroundColor;
export let matchStatus = "pre";
export let matchContentColor;
export let imageHomeTeam;
export let textHomeTeam;
export let imageAwayTeam;
export let textAwayTeam;
export let btnCupName;
export let textMatchDateTime;
export let textStadiumName;
export let matchDateTime;
export let sponsorBackgroundColor;
export let sponsorInfo;
export let btnActions;
export let textHomeScore;
export let textAwayScore;
export let textMatchMinutes;
export let txtFinal;
export let txtBroadcasters;
export let btnBroadcasters;
let atomWidthRem;
$: themeModeValue = matchStatus !== "live" ? "primary" : "primary";
$: [day, date, month, year, time, dayPeriod, timezone] =
    formatDateTimeCards(matchDateTime, CONFIG.timeZoneLocation, CONFIG.fixDateTimeFormat);
$: isLive = matchStatus === "live";
$: isMobile = atomWidthRem <= 29;
$: isTablet = atomWidthRem > 29 && atomWidthRem <= 53;
$: cupNameTextMultiplier = isMobile ? "0.416" : isTablet ? "0.666" : "1.00";
$: textMatchDateTimeMultiplier = isMobile
    ? "0.5"
    : isTablet
        ? "0.60"
        : "1.00";
$: teamIconSizeMultiplier = isMobile || isTablet ? "0.66" : "1.00";
$: teamNameTextMultiplier = isMobile ? "0.5" : isTablet ? "0.83" : "1.00";
$: sponsorInfoTextMultiplier = isMobile ? "0.4" : isTablet ? "0.7" : "1.00";
$: btnTextMultiplier = isMobile ? "0.58" : isTablet ? "0.83" : "1.00";
$: scoreTextMultiplier = isMobile ? "0.5" : isTablet ? "0.75" : "1.00";
$: matchMinsTextMultiplier = isMobile ? "0.5" : isTablet ? "0.77" : "1.00";
$: textFinalMultiplier = isMobile ? "0.5" : isTablet ? "0.56" : "1.00";
$: broadcasterImageSizeMultiplier = isMobile || isTablet ? "0.66" : "1.00";
$: matchDateDisplay = `${month} ${date}`;
$: matchTimeDisplay = `${time} ${dayPeriod}`;
$: displayButtons = btnActions === null || btnActions === void 0 ? void 0 : btnActions.slice(0, 2);
</script>

<BaseAtom
    _isotope="BoxScoreHorizontalFullAtom"
    _cssVariablePrefix="box-score-horizontal"
    {...merge({}, container, { themeMode: themeModeValue })}
    {overlayPadding}
    {containerBackgroundColor}
    {matchContentColor}
    {sponsorBackgroundColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:live-match={isLive}
    >
        <ButtonParticle
            {...merge({}, btnCupName, {
                text: {
                    size: `calc(${get(btnCupName, "text.size")} * ${cupNameTextMultiplier})`,
                },
            })}
        />
        {#if isLive}
            <div class="live-shape-container">
                <div class="live-shape" class:live-match={isLive}></div>
                <span>LIVE</span>
            </div>
        {/if}
        <div class="match-content">
            <div class="home-team">
                <ImageParticle
                    _block={true}
                    {...merge({}, imageHomeTeam, {
                        container: {
                            height: `calc(${get(imageHomeTeam, "container.height")} * ${teamIconSizeMultiplier})`,
                            width: `calc(${get(imageHomeTeam, "container.width")} * ${teamIconSizeMultiplier})`,
                        },
                    })}
                />
                <TextParticle
                    _block={true}
                    {...merge({}, textHomeTeam, {
                        size: `calc(${get(textHomeTeam, "size")} * ${teamNameTextMultiplier})`,
                    })}
                />
            </div>
            <div class="match-info">
                <!-- <ButtonParticle
                    {...merge({}, btnCupName, {
                        text: {
                            size: `calc(${get(btnCupName, "text.size")} * ${cupNameTextMultiplier})`,
                        },
                    })}
                /> -->
                {#if matchStatus === "pre"}
                    <div class="pre-match-content">
                        <TextParticle
                            {...textMatchDateTime}
                            size={`calc(${get(textMatchDateTime, "size")} * ${textMatchDateTimeMultiplier})`}
                            text={matchDateDisplay || ""}
                            _block={true}
                        />
                        <!-- <div
                            class="circle-shape"
                            class:mobile={isMobile || isTablet}
                        ></div> -->
                        <TextParticle
                            {...textMatchDateTime}
                            size={`calc(${get(textMatchDateTime, "size")} * ${textMatchDateTimeMultiplier})`}
                            text={matchTimeDisplay || ""}
                        />
                    </div>
                {/if}
                {#if isLive}
                    <div
                        class="live-match-content"
                        class:mobile-width={atomWidthRem <= 30}
                        class:tablet-width={atomWidthRem <= 45 &&
                            atomWidthRem > 30}
                    >
                        <TextParticle
                            {...textHomeScore}
                            size={`calc(${get(textHomeScore, "size")} * ${scoreTextMultiplier})`}
                        />
                        <TextParticle
                            {...textMatchMinutes}
                            size={`calc(${get(textMatchMinutes, "size")} * ${matchMinsTextMultiplier})`}
                            text={`${get(textMatchMinutes, "text")}'`}
                        />
                        <TextParticle
                            {...textAwayScore}
                            size={`calc(${get(textAwayScore, "size")} * ${scoreTextMultiplier})`}
                        />
                    </div>
                {/if}
                {#if matchStatus === "post"}
                    <div
                        class="post-match-content"
                        class:mobile-width={atomWidthRem <= 30}
                        class:tablet-width={atomWidthRem <= 45 &&
                            atomWidthRem > 30}
                    >
                        <TextParticle
                            {...textHomeScore}
                            size={`calc(${get(textHomeScore, "size")} * ${scoreTextMultiplier})`}
                        />
                        <TextParticle
                            {...merge({}, txtFinal, {
                                size: `calc(${get(txtFinal, "size")}* ${textFinalMultiplier})`,
                            })}
                        />
                        <TextParticle
                            {...textAwayScore}
                            size={`calc(${get(textAwayScore, "size")} * ${scoreTextMultiplier})`}
                        />
                    </div>
                {/if}
                <TextParticle
                    {...textStadiumName}
                    size={`calc(${get(textStadiumName, "size")} * ${cupNameTextMultiplier})`}
                    _block={true}
                />
            </div>
            <div class="away-team">
                <ImageParticle
                    _block={true}
                    {...merge({}, imageAwayTeam, {
                        container: {
                            height: `calc(${get(imageAwayTeam, "container.height")} * ${teamIconSizeMultiplier})`,
                            width: `calc(${get(imageAwayTeam, "container.width")} * ${teamIconSizeMultiplier})`,
                        },
                    })}
                />
                <TextParticle
                    _block={true}
                    {...merge({}, textAwayTeam, {
                        size: `calc(${get(textAwayTeam, "size")} * ${teamNameTextMultiplier})`,
                    })}
                />
            </div>
        </div>
        {#if sponsorInfo && sponsorInfo.text !== undefined}
            <div class="sponsor-content">
                <TextParticle
                    {...sponsorInfo}
                    size={`calc(${get(sponsorInfo, "size")} * ${sponsorInfoTextMultiplier})`}
                />
            </div>
        {/if}
        {#if btnBroadcasters?.[0]?.text?.text}
            <div class="broadcaster-content">
                <div class="broadcaster-text">
                    <TextParticle {...txtBroadcasters} />
                    <!-- size={`calc(${get(txtBroadcasters, "size")} * ${sponsorInfoTextMultiplier})`} -->
                </div>
                <div class="broadcaster-list">
                    {#each btnBroadcasters || [] as button}
                        <ButtonParticle
                            {...merge({}, button, {
                                text: {
                                    size: `calc(${get(button, "text.size")} * ${btnTextMultiplier})`,
                                },
                                image: {
                                    container: {
                                        width: `calc(${get(button, "image.container.width")} * ${broadcasterImageSizeMultiplier})`,
                                    },
                                },
                            })}
                        />
                    {/each}
                </div>
            </div>
        {/if}
        {#if displayButtons?.[0]?.text?.text && btnActions?.[0]?.text?.text}
            <div class="button-content" class:live-match={isLive}>
                {#each displayButtons || [] as button}
                    <ButtonParticle
                        {...merge({}, button, {
                            text: {
                                size: `calc(${get(button, "text.size")} * ${btnTextMultiplier})`,
                            },
                        })}
                    />
                {/each}
            </div>
        {/if}
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: relative;
        border: 4px solid transparent;
        overflow: hidden;
        border-color: var(
            --box-score-horizontal-container-background-color,
            var(--theme-mode-background-color, unset)
        );
        background-color: var(
            --box-score-horizontal-container-background-color,
            var(--theme-mode-background-color, unset)
        );
    }
    .live-shape-container {
        background-color: var(
            --theme-mode-background-color,
            #FFFFFF);
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        text-align: center;
    }

    .live-shape {
        width: 9.85rem;
        height: 2.625rem;
        transform: perspective(30px) rotateX(-2deg);
        border-radius: 5px;
        background-color: var(--theme-mode-background-color);
    }

    .main-container.layout-tablet-width > .live-shape-container > .live-shape {
        width: 6rem;
        height: 1.625rem;
    }

    .main-container.layout-mobile-width > .live-shape-container > .live-shape {
        width: 4.1875rem;
        height: 1.125rem;
    }

    .live-shape.live-match {
        background-color: var(
            --box-score-horizontal-container-background-color,
            var(--theme-mode-background-color, unset)
        );
        transform: perspective(4.5px) rotateX(-2deg);
    }

    .live-shape-container > span {
        text-align: center;
        color: var(--theme-mode-foreground-color, #000);
        font-size: 1.75rem;
        font-weight: 900;
        -webkit-text-decoration: none;
        text-decoration: none;
        position: absolute;
    }

    .main-container.layout-tablet-width > .live-shape-container > span {
        font-size: 1.25rem;
        line-height: 1.375rem;
    }

    .main-container.layout-mobile-width > .live-shape-container > span {
        font-size: 0.75rem;
        line-height: 1rem;
    }

    .match-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0rem 1.5rem 0.75rem 1.5rem;
        background-color: var(--box-score-horizontal-match-content-color, #fff);
        border: none;
    }
    .main-container.layout-tablet-width .match-content {
            padding: 0.75rem 0.75rem;
        }
    .main-container.layout-tablet-width .match-content .match-info .pre-match-content {
                    justify-content: center;
                    -webkit-column-gap: 0.2rem;
                       -moz-column-gap: 0.2rem;
                            column-gap: 0.2rem;
                    flex-wrap: wrap;
                }
    .main-container.layout-tablet-width .button-content {
            padding: 0;
        }

    .main-container.layout-mobile-width > .match-content {
        padding: 0.75rem 0.5rem;
    }

    .main-container.layout-mobile-width > .match-content .match-info .pre-match-content {
                justify-content: center;
                -webkit-column-gap: 0.25rem;
                   -moz-column-gap: 0.25rem;
                        column-gap: 0.25rem;
                row-gap: 0;
                flex-wrap: wrap;
            }

    .main-container.layout-mobile-width > .button-content {
        padding: 0;
        flex-wrap: nowrap;
        gap: 0;
    }

    .main-container.layout-mobile-width > .broadcaster-content {
        padding: 0.5rem 0;
    }

    .main-container.layout-mobile-width > .sponsor-content {
        padding: 0.5rem 0;
    }

    .match-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        /* height: 100%; */
        justify-content: space-evenly;
        gap: 0.5rem;
        width: 100%;
    }

    .sponsor-content {
        width: 100%;
        text-align: center;
        padding: 0.75rem 0;
    }

    .sponsor-content {
        width: 100%;
        text-align: center;
        background-color: var(
            --box-score-horizontal-sponsor-background-color,
            #e5e5e5
        );
        padding: 0.75rem 0;
    }

    .button-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 1.25rem 1rem;
        background-color: var(
            --box-score-horizontal-sponsor-background-color,
            var(--theme-mode-background-color)
        );
        color: var(--theme-mode-accent-color);
        text-align: center;
    }
    
    .broadcaster-text {
        text-align: center;
    }

    .home-team,
    .away-team {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
    }

    .button-content.live-match {
        background-color: var(
            --box-score-horizontal-sponsor-background-color,
            var(--theme-mode-background-color)
        );
        color: var(--theme-mode-accent-color);
    }

    .pre-match-content {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* gap: 0.5rem; */
    }
    .post-match-content,
    .live-match-content {
        display: flex;
        justify-content: space-around;
        /* gap: 2.625rem; */
        align-items: center;
        width: 100%;
    }

    .post-match-content.mobile-width,
    .live-match-content.mobile-width {
        /* gap: 1.75rem; */
    }

    .circle-shape {
        background-color: #000;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
    }
    .circle-shape.mobile {
        width: 0.4rem;
        height: 0.4rem;
    }</style>
