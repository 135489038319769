<script context="module" lang="ts">var _a, _b;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b;
import _, { get, merge, isNil } from "lodash";
import { t } from "js/i18n";
import { BaseAtom, getContentManager, } from "components/cards/atoms";
import { ImageParticle, TextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { setStoreOnHoverAction, formatDuration, getPublishDuration, translateString } from "components/cards/utilities";
import { writable } from "svelte/store";
import { DateTime } from "luxon";
export let container;
export let mediaType;
export let slug;
export let link;
export let titleText;
export let descriptionText;
export let thumbnail;
export let imageClockIcon;
export let publishDateTime;
export let imageShareIcon;
export let textDateTime;
export let bodyPadding;
export let mediaIconColor;
export let imgMediaType;
export let separateHeaderDate;
export let viewTimeDuration;
export let views;
export let shouldDisplayTime;
export let shouldDisplayDuration;
export let shouldDisplayViews;
export let categories;
export let shouldDisplayCategories;
export let shouldDisplayShare;
export let totalPhotos;
export let imgCameraIcon;
export let shouldDisplayPhotosCount;
export let header;
export let photosCountPosition;
export let contentAvailable;
$: contentAvailable = !!slug;
$: descriptionVisible =
    (descriptionText === null || descriptionText === void 0 ? void 0 : descriptionText.text) && !((_a = descriptionText === null || descriptionText === void 0 ? void 0 : descriptionText.container) === null || _a === void 0 ? void 0 : _a.hidden);
const hovering = writable(false);
let atomWidthRem;
const formatDateTime = (dateString) => {
    var _a;
    const dt = DateTime.fromISO(dateString, { zone: "Europe/London" });
    const convertedDateTime = (_a = dt
        .toLocaleString(Object.assign(Object.assign({ weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" }, DateTime.DATE_FULL), DateTime.TIME_24_SIMPLE))) === null || _a === void 0 ? void 0 : _a.split(" ");
    return convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.map((string) => string.trim().replace(",", ""));
};
$: mtc = getContentManager().getMediaTypeConfigurationStore(mediaType, slug, link);
$: [firstPos, secondPos] = (photosCountPosition === null || photosCountPosition === void 0 ? void 0 : photosCountPosition.split("-")) || ["top", "right"];
$: resolvedLink = (_b = $mtc === null || $mtc === void 0 ? void 0 : $mtc.linkUrl) === null || _b === void 0 ? void 0 : _b.href;
$: isTablet = atomWidthRem <= 70 && atomWidthRem > 40;
$: isMobile = atomWidthRem <= 40;
$: mediaTypeIcon = $mtc === null || $mtc === void 0 ? void 0 : $mtc.icon;
$: mediaIconMultiplier = atomWidthRem <= 30 ? "0.6" : "1.00";
$: viewDurationDisplay = translateString(formatDuration(viewTimeDuration), $t);
$: publishDateTimeDisplay = getPublishDuration(publishDateTime, $t);
</script>

<BaseAtom
  _isotope="VerticalMediaAtom"
  _link={resolvedLink}
  _cssVariablePrefix="vertical-media"
  {...container}
  {bodyPadding}
  bind:_clientWidthRem={atomWidthRem}
>
  <div
    class="main-container"
    use:setStoreOnHoverAction={hovering}
    class:mobile-layout={atomWidthRem < 45}
  >
    <div class="image-container">
      <ImageParticle _block={true} _hovering={$hovering} {...thumbnail} />
      {#if shouldDisplayPhotosCount && +totalPhotos > 0}
        <div class="photos-count" style={`${firstPos}:1rem; ${secondPos}:1rem`}>
          <ImageParticle {...imgCameraIcon} _block={true} />
          <TextParticle {...header} text={`${totalPhotos}`} />
        </div>
      {/if}
    </div>
    <div class="body-container">
      <div class="inner-container">
        <div
          class="text-clock-share"
          class:separate-header-date={separateHeaderDate}
        >
          <div class="text-container">
            <div class="categories-box">
              {#if shouldDisplayCategories}
                <div class="category-list">
                  {#each categories || [] as category}
                    <ButtonParticle {...category} />
                  {/each}
                </div>
              {/if}
            </div>
            <TextParticle _block={true} {...titleText} />
          </div>
          {#if descriptionVisible}
            <div class="description-container">
              <TextParticle {...descriptionText} />
            </div>
          {/if}
          <div class="icon-container">
            <div class="left-side-content">
              {#if shouldDisplayTime && !isNil(publishDateTime)}
                <div class="clock-duration">
                  <div>
                    <ImageParticle _block={true} {...imageClockIcon} />
                  </div>
                  <div>
                    <TextParticle
                      {...textDateTime}
                      text={publishDateTimeDisplay || ""}
                      _block={true}
                    />
                  </div>
                </div>
              {/if}
              {#if shouldDisplayDuration && !isNil(viewTimeDuration)}
                <div class="read-time-box">
                  <span>|</span>
                  <div>
                    <ImageParticle
                      {...imageClockIcon}
                      _block={true}
                      icon="yc_icon_book"
                    />
                  </div>
                  <div>
                    <TextParticle
                      {...textDateTime}
                      text={` ${viewDurationDisplay || ""}`}
                      _block={true}
                    />
                  </div>
                </div>
              {/if}
              {#if shouldDisplayViews && !isNil(views)}
                <div class="views-count">
                  <TextParticle
                    {...textDateTime}
                    text="| {viewsDisplay || ''} {$t('views')}"
                    wrap="nowrap"
                  />
                </div>
              {/if}
            </div>
          </div>
        </div>
        <div class="media-type-icon">
          <div class="media-icon">
            {#if !_.isNil(mediaTypeIcon)}
              <ImageParticle
                _block={true}
                {...merge({}, imgMediaType, {
                  container: {
                    width: `calc(${get(imgMediaType, "container.width")}*${mediaIconMultiplier})`,
                    height: `calc(${get(imgMediaType, "container.height")}*${mediaIconMultiplier})`,
                  },
                  icon: mediaTypeIcon,
                  iconColor: mediaIconColor,
                })}
              />
            {/if}
          </div>
          {#if shouldDisplayShare}
            <div class="right-side-content">
              <ButtonParticle {...imageShareIcon} />
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</BaseAtom>

<style>
  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    background-position: top center;
    cursor: pointer;
    position: relative;
    /*aspect-ratio: var(--aspect-ratio, 21/4);*/
    height: 100%;
    width: 100%;
  }

  .main-container > .image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .main-container.mobile-layout .left-side-content {
      /* align-items: flex-end; */
    }

  .media-type-icon {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  .body-container {
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: var(--vertical-media-body-padding, unset);
    gap: 0.5rem;
  }

  .text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .text-container > * {
    flex: 0 1 max-content;
  }

  .text-clock-share {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .text-clock-share.separate-header-date {
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .inner-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .description-container {
    width: 100%;
  }

  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .left-side-content {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: flex-start;
    /* flex-basis: min-content; */
    flex-wrap: wrap;
  }

  .clock-duration {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
  }
  .category-list {
    display: flex;
    flex-direction: row;
    align-items: start;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    flex-wrap: wrap;
  }

  span {
    color: var(--theme-mode-foreground-color, #fff);
    font-size: 1.25rem;
  }

  .photos-count {
    position: absolute;
    /* top: 1rem;
    right: 1rem; */
    display: flex;
    align-items: center;
    background: var(--theme-mode-highlight-color);
    border-radius: 8px;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  }
  .read-time-box {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }</style>
