<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { onMount } from "svelte";
import { Toggle } from 'framework7-svelte';
import RichTextParticle from "components/cards/particles/YinzCam/Core/RichText/RichTextParticle.svelte";
export let container;
export let overlayPadding;
export let txtHeading;
export let txtConsent;
export let txtAcceptanceText;
export let btnAcceptAll;
export let btnAcceptEssential;
export let btnAcceptSelected;
export let btnReject;
export let btnCookieSettings;
export let settingsOptions = [];
export let btnCloseButton;
let atomWidthRem;
let visible = false;
let settingsMode = false;
let consentChoices = {};
$: isMobile = atomWidthRem <= 65;
$: isTablet = atomWidthRem <= 85 && atomWidthRem > 65;
function gtag(...args) {
    if (window.gtag) {
        window.gtag(...args);
    }
}
onMount(() => {
    gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        wait_for_update: 500,
    });
    if (window.localStorage.getItem('cookie_consent') !== 'true' && window.sessionStorage.getItem('cookie_reject') !== 'true') {
        setTimeout(() => {
            visible = true;
        }, 1000);
    }
});
function closePopup() {
    visible = false;
}
function handleAccept() {
    window.localStorage.setItem('cookie_consent', "true");
    if (settingsMode) {
        const choiceFunc = (choiceKey) => consentChoices[choiceKey] ? 'granted' : 'denied';
        gtag('consent', 'update', {
            ad_storage: choiceFunc('marketing'),
            analytics_storage: choiceFunc('analytical'),
            ad_user_data: choiceFunc('marketing'),
            ad_personalization: choiceFunc('marketing'),
        });
    }
    else {
        // accept all
        gtag('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
        });
    }
    closePopup();
}
function handleReject() {
    window.sessionStorage.setItem('cookie_reject', "true");
    gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
    });
    closePopup();
}
function handleSettings() {
    settingsMode = true;
}
function handleAcceptEssential() {
    handleReject();
}
function handleClose() {
    handleReject();
}
function onToggleChange(e) {
    consentChoices[this] = e;
}
</script>

<BaseAtom
    _isotope="CookieConsentPopup"
    _cssVariablePrefix="cookies-popup"
    {...container}
    {overlayPadding}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:mobile-layout={isMobile}
        class:tablet-layout={isTablet}
        class:cookie-popup-visible={visible}
    >
        <div class="text-button-area">
            <div class="text-icon">
                <!-- team logo div was removed from here -->
                <div class="consent-text-area">
                    {#if !settingsMode}
                        <TextParticle {...txtHeading} />
                        <RichTextParticle {...txtConsent} />
                        <RichTextParticle {...txtAcceptanceText} />
                    {:else}
                        <div class="settings-consent-container">
                            {#each (settingsOptions || []) as option}
                                <div class="settings-consent">
                                    <TextParticle {...option.txtHeading} />
                                    <RichTextParticle {...option.txtConsent} />
                                    <div class="settings-consent-spacer"></div>
                                    {#if option.showToggle}
                                        <Toggle onToggleChange={onToggleChange.bind(option.consentKey)} />
                                    {/if}
                                </div>
                            {/each}
                        </div>
                    {/if}
                </div>
            </div>
            <div class="button-area">
                {#if !settingsMode}
                <ButtonParticle {...btnAcceptAll} on:click={handleAccept} />
                <ButtonParticle {...btnAcceptEssential} on:click={handleAcceptEssential} />
                {:else}
                <ButtonParticle {...btnAcceptSelected} on:click={handleAccept} />
                {/if}
                <ButtonParticle {...btnReject} on:click={handleReject} />
                {#if !settingsMode}
                    <ButtonParticle {...btnCookieSettings} on:click={handleSettings} />
                {/if}
            </div>
        </div>
        <div class="close-image">
            <ButtonParticle {...btnCloseButton} on:click={handleClose} />
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        padding: var(--cookies-popup-overlay-padding, unset);
        display: none;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        position: fixed;
        bottom: 0;
        left: 0;
        background-color: var(--theme-mode-background-color, white);
        z-index: -1;
        /* opacity: 0; */
        transition: opacity 1s ease;

        --f7-toggle-width: 3.25rem;
        --f7-toggle-height: 1.5rem;
        --f7-toggle-border-color: #e5e5e5;
        --f7-toggle-inactive-color: #989898;
        --f7-toggle-active-color: var(--theme-mode-accent-color, black);

        max-height: 75vh;
        overflow-y: auto;
    }

    .main-container.cookie-popup-visible {
        z-index: 10;
        /* opacity: 1; */
        display: flex;
    }

    .main-container.mobile-layout {
        padding: 1.5rem;
        /*flex-wrap: wrap;*/
    }

    .text-button-area {
        display: flex;
        gap: 2rem;
        align-items: top;
    }

    .main-container.mobile-layout .text-button-area {
        flex-direction: column;
        gap: 3rem;
    }

    .text-icon {
        display: flex;
        gap: 0.75rem;
    }

    .consent-text-area {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.75rem;
    }

    .settings-consent-container {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .main-container.mobile-layout .settings-consent-container {
        grid-auto-columns: unset;
        grid-auto-rows: -webkit-min-content;
        grid-auto-rows: min-content;
        grid-auto-flow: row;
        gap: 3rem;
    }

    .settings-consent {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: top;
        gap: 0.75rem;
    }

    .settings-consent > :global(*) {
        flex: 0;
        align-self: start;
    }
    
    .settings-consent-spacer {
        flex: 1;
        min-height: 3rem;
    }

    .main-container.mobile-layout .settings-consent-spacer {
        flex: 1;
        min-height: unset;
    }

    .settings-consent :global(.toggle input[type="checkbox"]:checked + .toggle-icon:after)  {
        background-color: var(--theme-mode-background-color);
    }

    .button-area {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: end;
    }
    .close-image {
        align-self: flex-start;
        margin-left: 2rem;
    }

    
    .main-container.mobile-layout > .close-image{
        margin-left: 0;
    }</style>
