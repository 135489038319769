<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { getContext } from 'svelte';
import 'svelte/store';
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, } from "components/cards/particles/YinzCam";
import { resolveUrl, shouldOpenInNewWindow } from "js/url";
export let container;
export let slug;
export let headingText;
export let pages;
export let contentAvailable;
const isSubMenuClicked = getContext('subMenuClickContext');
const handleSubMenuClick = () => {
    isSubMenuClicked === null || isSubMenuClicked === void 0 ? void 0 : isSubMenuClicked.set(true);
};
$: contentAvailable = !!slug;
</script>

<style>
  ul.page-list-group {
    font-size: var(--dropdown-font-size, unset);
    color: var(--dropdown-font-color, unset);
    padding: 0; /* remove default padding */
    margin: 0; /* remove default margin */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1.25rem;
  }

  ul.page-list-group > li {
    color: var(--theme-primary-foreground-color, black);
    padding: var(--dropdown-item-padding, unset);
    -webkit-text-decoration: none;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  ul.page-list-group > li.group-title {
    color: var(--theme-primary-accent-color, black);
    text-transform: uppercase;
    cursor: default;
    letter-spacing: 0.03125rem;
    font-size: 1.25rem;
    font-weight: 800;
  }</style>

<BaseAtom _isotope="NavigationSubmenuAtom" {...container}>
  <ul class="page-list-group">
    {#if headingText}
      <li class="group-title" on:click={handleSubMenuClick}>
        <TextParticle {...headingText}/>
      </li>
    {/if}
    {#each pages || [] as page}
      <li>
        <a
          on:click={handleSubMenuClick}
          class:external={resolveUrl(page?.linkUrl).external}
          target={shouldOpenInNewWindow(resolveUrl(page?.linkUrl).href)
            ? "_blank"
            : "_self"}
          href={resolveUrl(page?.linkUrl).href}
        >
          <TextParticle {...page?.pageName}/>
          </a>
      </li>
    {/each}
  </ul>
</BaseAtom>
