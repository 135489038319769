import { generateArraySchema, generateBooleanSchema, generateDateTimeSchema, generateDurationSchema, generateNumberSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Image Background Media", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'overlay' },
    uneditableFields: { background: false }
  }),
  overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
    defaultValue: '1rem'
  }),
  mediaType: generateStringSchema("Type", "The type of this media item. This controls the media-type indicator icon as well as the default media item deep link.", {
    choices: ['article', 'video', 'gallery', 'audio'],
    choiceTitles: ["News Article", "Video", "Photo Gallery", "Audio"]
  }),
  slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
  link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
  publishDateTime: generateDateTimeSchema("Publish Date and Time", "The date and time that this content was published."),
  viewTimeDuration: generateDurationSchema("View Time Duration", "The duration that an average user would take to completely consume this content."),
  views: generateNumberSchema("Views", "The number of views of this content."),
  thumbnail: ImageParticleSpec.generateSchema("Thumbnail", "The image thumbnail for the background of the card.", {
    defaultValues: { sizing: 'cover', hoverZoomEnabled: true, darkenFilterEnabled: true, fitVerticalAlign: 'top', role: 'general' },
  }),
  secondaryThumbnail: ImageParticleSpec.generateSchema("Secondary Thumbnail", "The secondary thumbnail for the background of the card.", {
    defaultValues: { sizing: 'cover', hoverZoomEnabled: true, darkenFilterEnabled: true, fitVerticalAlign: 'top', role: 'general' },
  }),
  categories: generateArraySchema("Categories", "The categories to list for the media item.",
    ButtonParticleSpec.generateSchema("Category", "A category for the media item.", {
      defaultValues: { showImage: false, container: { themeMode: 'inverted', background: { visible: true }, border: { visible: true, radius: '8px' }, padding: '0.5rem 0.75rem' }, text: { size: '1rem', weight: '600' } }
    })
  ),
  header: TextParticleSpec.generateSchema("Header Text", "The text styling for the header text.", {
    defaultValues: { size: '1rem', weight: '600', transform: 'capitalize' },
    uneditableFields: { text: true }
  }),
  titleText: TextParticleSpec.generateSchema("Title Text", "The title text.", {
    defaultValues: { size: '2rem', weight: '900', lineHeight: 'normal' },
  }),
  descriptionText: TextParticleSpec.generateSchema("Description Text", "The description text.", {
    defaultValues: { size: '1rem', weight: '600' },
  }),
  cta: ButtonParticleSpec.generateSchema("CTA", "The CTA for the media item.", {
    defaultValues: { showImage: false, container: { themeMode: 'primary', background: { visible: true, useHighlightColor: true }, border: { visible: true, radius: '8px' }, padding: '0.75rem 1.25rem' }, text: { size: '1.25rem', weight: '600' } }
  }),
  imageGradient: generateBooleanSchema("Apply gradient to BG?", "Select if a gradient should be applied for BG Image"),
  gradientColor: generateColorSchema("Color for BG Gradient", "Pick a gradient color for BG"),
  mediaIconColor: generateColorSchema("Media Type Icon Color", "Pick a color for media type icon"),
  shouldDisplayCategories: generateBooleanSchema("Should display categories?", "Specify if categories should be displayed"),
  dateAndIconOrientation: generateStringSchema("Change Date and Icon position", "Change the position of Date and Media Icon", {
    choices: ["primary", "secondary"],
    choiceTitles: ["Primary", "Secondary"],
    defaultValue: "primary",
  }),
  invertDatePosition: generateStringSchema("Invert date position", "Toggle date position to top or bottom of the card", {
    choices: ["top", "bottom"],
    choiceTitles: ["Top", "Bottom"],
    defaultValue: "top",
  }),
  imgClockIcon: ImageParticleSpec.generateSchema("Clock Icon", "Select/upload an icon for clock", {
    defaultValues: { container: { width: "1.25rem", height: "1.25rem", margin: "0 0.5rem 0 0" }, icon: "yc_icon_clock" }
  }),
  shouldDisplayMediaIcon: generateBooleanSchema("Should Display Media Icon?", "Should Display Media Icon?", {
    defaultValue: true
  }),
  shouldDisplayReadDuration: generateBooleanSchema("Should Display Read Duration?", "Should Display Read Duration?", {
    defaultValue: true
  }),
  shouldDisplaySecondaryImage: generateBooleanSchema("Should Display Secondary Image?", "Should Display Secondary Image?", {
    defaultValue: false
  }),
  imgCameraIcon: ImageParticleSpec.generateSchema("Camera Icon", "Select/upload an icon for Camera", {
    defaultValues: { container: { width: "2rem", height: "2rem" }, icon: "yc_icon_camera" }
  }),
  totalPhotos: generateStringSchema("Total Photos in Album", "Total Photos in Album"),
  shouldDisplayPhotosCount: generateBooleanSchema("Should Display Photos Count?", "Should Display Photos Count?", {
    defaultValue: true,
  }),
  photosCountPosition: generateStringSchema("Photos Count Pill Position", "Photos Count Pill Position", {
    choices: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
    choiceTitles: ['Top Left', 'Top Right', 'Bottom Left', 'Bottom Right'],
    defaultValue: 'top-right'
  })
}));
