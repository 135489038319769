
<script lang="ts">import _, { merge } from "lodash";
import { CaptionedParticle } from "components/cards/particles/YinzCam";
import "./ArticleBodyNode";
import ArticleBodyParticleWrapper from "./ArticleBodyParticleWrapper.svelte";
import { buildInlineStyle } from "components/cards/utilities";
import TextParticle from "components/cards/particles/YinzCam/Core/Text/TextParticle.svelte";
export let node = undefined;
export let textDefaults = undefined;
export let imageDefaults = undefined;
export let videoDefaults = undefined;
export let galleryDefaults = undefined;
export let audioDefaults = undefined;
export let webEmbedDefaults = undefined;
export let captionDefaults = undefined;
let computedTextColor;
$: style = buildInlineStyle('article-body-node-text-color', computedTextColor);
</script>

<style>
  .article-node.node-type-image,
  .article-node.node-type-video {
    align-self: center;
  }

  .article-node.node-type-blockquote{
    /*Styles copied from Contentful to match the same look and feel*/
    border-left: 6px solid rgb(231, 235, 238);
    margin: 0px 0px 1.3125rem;
    padding-left: 0.875rem;
  }

  .article-list.with-computed-color > li.article-list-item {
    color: var(--article-body-node-text-color, unset);
  }

  table.article-table {
    border-collapse: collapse;
    width: 100%;
  }

  table.article-table td, table.article-table th {
    border: 1px solid lch(from var(--theme-mode-primary-color) max(l, 70) c h);
    padding: 8px;
  }

  table.article-table.with-computed-color td, table.article-table.with-computed-color th {
    border: 1px solid lch(from var(--article-body-node-text-color, var(--theme-mode-primary-color)) max(l, 70) c h);
  }

  table.article-table tr:nth-child(even){
    backdrop-filter: brightness(95%);
    -webkit-backdrop-filter: brightness(95%);
  }

  table.article-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: var(--theme-inverted-background-color);
    /*
    backdrop-filter: brightness(85%);
    -webkit-backdrop-filter: brightness(85%);
    */
  }</style>

<div class={`article-node node-type-${node.type}`}>
  {#if node}
  {#if node.type === 'ol' && node.nodes && node.nodes.length > 0}
  <TextParticle _block={true} bind:_computedColor={computedTextColor} {...textDefaults} text={''} />
  <ol class='article-list' class:with-computed-color={computedTextColor} {style}>
    {#each node.nodes as itemNode}
    {#if itemNode && itemNode.nodes && itemNode.nodes.length > 0}
    <li class='article-list-item'>
    {#each itemNode.nodes as subNode}
      <svelte:self node={subNode} {textDefaults} {imageDefaults} {videoDefaults} {galleryDefaults} {audioDefaults} {webEmbedDefaults} {captionDefaults} />
    {/each}
    </li>
    {/if}
    {/each}
  </ol>
  {:else if node.type === 'ul' && node.nodes && node.nodes.length > 0}
  <TextParticle _block={true} bind:_computedColor={computedTextColor} {...textDefaults} text={''} />
  <ul class='article-list' class:with-computed-color={computedTextColor} {style}>
    {#each node.nodes as itemNode}
    {#if itemNode && itemNode.nodes && itemNode.nodes.length > 0}
    <li class='article-list-item'>
    {#each itemNode.nodes as subNode}
      <svelte:self node={subNode} {textDefaults} {imageDefaults} {videoDefaults} {galleryDefaults} {audioDefaults} {webEmbedDefaults} {captionDefaults} />
    {/each}
    </li>
    {/if}
    {/each}
  </ul>
  {:else if node.type === 'table' && node.nodes && node.nodes.length > 0}
  <TextParticle _block={true} bind:_computedColor={computedTextColor} {...textDefaults} text={''} />
  <table class='article-table' class:with-computed-color={computedTextColor} {style}>
    {#if node.nodes[0] && node.nodes[0].nodes && node.nodes[0].nodes.length > 0 && node.nodes[0].type === 'table-header-row'}
    <thead>
      <tr>
      {#each node.nodes[0].nodes as cellNode}
      {#if cellNode && cellNode.nodes && cellNode.nodes.length > 0}
        <th>
        {#each cellNode.nodes as containerNode}
          <svelte:self node={containerNode} textDefaults={_.merge({}, textDefaults, { container: { themeMode: 'inverted' } })} {imageDefaults} {videoDefaults} {audioDefaults} {webEmbedDefaults} {captionDefaults} />
        {/each}
        </th>
      {/if}
      {/each}
      </tr>
    </thead>
    {/if}
    <tbody>
    {#each node.nodes as rowNode}
    {#if rowNode && rowNode.nodes && rowNode.nodes.length > 0 && rowNode.type !== 'table-header-row'}
      <tr>
      {#each rowNode.nodes as cellNode}
      {#if cellNode && cellNode.nodes && cellNode.nodes.length > 0}
        <td>
        {#each cellNode.nodes as containerNode}
          <svelte:self node={containerNode} {textDefaults} {imageDefaults} {videoDefaults} {galleryDefaults} {audioDefaults} {webEmbedDefaults} {captionDefaults} />
        {/each}
        </td>
      {/if}
      {/each}
      </tr>
    {/if}
    {/each}
    </tbody>
  </table>
  {:else if node.captionProps}
  <CaptionedParticle {...merge({}, captionDefaults, node.captionProps)}>
    <ArticleBodyParticleWrapper {node} {textDefaults} {imageDefaults} {videoDefaults} {galleryDefaults} {audioDefaults} {webEmbedDefaults} />
  </CaptionedParticle>
  {:else}
  <ArticleBodyParticleWrapper {node} {textDefaults} {imageDefaults} {videoDefaults} {galleryDefaults} {audioDefaults} {webEmbedDefaults} />
  {/if}
  {/if}
</div>
