<Boundary>
  {#if outlineMode}
  <CardsContainerWrapper style="width: 100%; overflow-x: scroll; display: grid; grid-auto-flow: column; grid-auto-columns: 200px; grid-column-gap: 10px; justify-content: safe center;" {editorOptions}>
    {#each layout.elements as element, i (element.id)}
      <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} height="100%" updater={elementUpdater.bind(null, i)} />
    {/each}
  </CardsContainerWrapper>
  {:else}
  <CardsContainerWrapper style="width: 100%;" {editorOptions}>
    <swiper-container
      bind:this={swiperComponent}
      init={false}
      simulate-touch={false}
      navigation={!_.isNil(layout.swiperNavigation) && layout.swiperNavigation}
      pagination={!!layout.swiperPagination}
      speed={layout.scrollSpeed || 400}
      slides-per-view={layout.maxColumns || 'auto'}
      space-between={layout.gapPixels || 0}
      effect={swipeEffect}
      free-mode={!_.isNil(layout.slideSnap) && !layout.slideSnap}
      observer={true}
      observe-slide-children={false}
      center-insufficient-slides={false}
      slides-per-group={layout.slidesPerGroup || 1}
      grab-cursor={true}
      coverflow-effect-rotate={layout.coverflowRotate || 0}
      coverflow-effect-stretch={layout.coverflowStretch || 0}
      coverflow-effect-depth={layout.coverflowDepth || 100} 
      coverflow-effect-modifier={layout.coverflowModifier || 4}
      coverflow-effect-slide-shadows={false}
      loop={!!layout.loop}
      autoplay={!!layout.autoplay}
      lazy-preload-prev-next={layout.preloadSlideCount || 3}
      loop-additional-slides={layout.loopExtraSlides || 3}
    >
    {#if layout?.elements?.length >= 1}
      <div slot="slide-0" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 0)} element={layout.elements[0]} updater={elementUpdater.bind(null, 0)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 2}
      <div slot="slide-1" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 1)} element={layout.elements[1]} updater={elementUpdater.bind(null, 1)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 3}
      <div slot="slide-2" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 2)} element={layout.elements[2]} updater={elementUpdater.bind(null, 2)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 4}
      <div slot="slide-3" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 3)} element={layout.elements[3]} updater={elementUpdater.bind(null, 3)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 5}
      <div slot="slide-4" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 4)} element={layout.elements[4]} updater={elementUpdater.bind(null, 4)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 6}
      <div slot="slide-5" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 5)} element={layout.elements[5]} updater={elementUpdater.bind(null, 5)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 7}
      <div slot="slide-6" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 6)} element={layout.elements[6]} updater={elementUpdater.bind(null, 6)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 8}
      <div slot="slide-7" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 7)} element={layout.elements[7]} updater={elementUpdater.bind(null, 7)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 9}
      <div slot="slide-8" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 8)} element={layout.elements[8]} updater={elementUpdater.bind(null, 8)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 10}
      <div slot="slide-9" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 9)} element={layout.elements[9]} updater={elementUpdater.bind(null, 9)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 11}
      <div slot="slide-10" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 10)} element={layout.elements[10]} updater={elementUpdater.bind(null, 10)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 12}
      <div slot="slide-11" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 11)} element={layout.elements[11]} updater={elementUpdater.bind(null, 11)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 13}
      <div slot="slide-12" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 12)} element={layout.elements[12]} updater={elementUpdater.bind(null, 12)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 14}
      <div slot="slide-13" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 13)} element={layout.elements[13]} updater={elementUpdater.bind(null, 13)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 15}
      <div slot="slide-14" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 14)} element={layout.elements[14]} updater={elementUpdater.bind(null, 14)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 16}
      <div slot="slide-15" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 15)} element={layout.elements[15]} updater={elementUpdater.bind(null, 15)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 17}
      <div slot="slide-16" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 16)} element={layout.elements[16]} updater={elementUpdater.bind(null, 16)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 18}
      <div slot="slide-17" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 17)} element={layout.elements[17]} updater={elementUpdater.bind(null, 17)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 19}
      <div slot="slide-18" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 18)} element={layout.elements[18]} updater={elementUpdater.bind(null, 18)} on:contentLoaded />
      </div>
    {/if}
    {#if layout?.elements?.length >= 20}
      <div slot="slide-19" class="cards-array-slide-wrapper">
        <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, 19)} element={layout.elements[19]} updater={elementUpdater.bind(null, 19)} on:contentLoaded />
      </div>
    {/if}
    </swiper-container>
  </CardsContainerWrapper>
  {/if}
</Boundary>

<style>
  /*
      {#each layout.elements as element, i (element.id)}
    <div slot="slide-0" class="cards-array-slide-wrapper">
      <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} updater={elementUpdater.bind(null, i)} />
    </div>
    {/each}
    */

  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cards-array-slide-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }</style>

<script lang="ts" context="module">import { register } from 'swiper/swiper-element-bundle.mjs';
register();
</script>

<script lang="ts">import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
import { CardsPageContextKey } from "./context";
import { getContext, onMount } from "svelte";
import './actions';
import { setImageNoLazyLoadContext } from '../particles/YinzCam';
import _ from 'lodash';
export let parentSequenceId = "";
export let layout;
export let updater;
export const messageHandler = (action) => {
    switch (action === null || action === void 0 ? void 0 : action.effect) {
        case 'carousel-left': {
            onPrev();
            break;
        }
        case 'carousel-right': {
            onNext();
            break;
        }
    }
};
let swiperComponent = null;
const onPrev = () => {
    var _a;
    (_a = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper) === null || _a === void 0 ? void 0 : _a.slidePrev(layout.scrollSpeed || 400);
};
const onNext = () => {
    var _a;
    (_a = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper) === null || _a === void 0 ? void 0 : _a.slideNext(layout.scrollSpeed || 400);
};
const pageContext = getContext(CardsPageContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
$: outlineMode = $emmEnabled && !$emmPreview;
$: originalSwipeEffect = (layout.swipeEffect || '').toLowerCase();
$: swipeEffect = (function (_swipeEffect) {
    _swipeEffect = (_swipeEffect || 'slide').toLowerCase();
    switch (_swipeEffect) {
        case 'magnify':
            _swipeEffect = 'coverflow';
            break;
    }
    return _swipeEffect;
})(layout.swipeEffect);
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
function onSwiperInit(swiper) {
    if (layout.initialSlideNumber > 0) {
        for (let i = 0; i < layout.initialSlideNumber; i++) {
            swiper.slideNext(0);
        }
    }
    // little hack to get it to load previous slides 
    if (layout.loop) {
        swiper.slideNext(0);
        swiper.slidePrev(0);
    }
}
onMount(() => {
    swiperComponent.on = {
        afterInit: onSwiperInit
    };
    swiperComponent.initialize();
});
setImageNoLazyLoadContext(true);
</script>
