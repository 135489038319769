<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import "components/cards/particles/YinzCam";
import ImageParticle from "components/cards/particles/YinzCam/Core/Image/ImageParticle.svelte";
import { hideBrokenImgDisplayAltText } from "components/cards/utilities";
export let container = undefined;
export let imgLeagueLogo = undefined;
export let layout = undefined;
export let maxRows = undefined;
export let centerOnTeam = undefined;
export let highlightTeam = undefined;
export let tableColumns = [];
export let tableData = [];
export let hasGroupData = undefined;
export let groupTableData = [];
export let groupNames = undefined;
export let groupHeaderBGColor = undefined;
export let teamShadowFlag = undefined;
let atomWidthPx;
let filteredTableData = [];
$: if (tableData) {
    if (centerOnTeam && maxRows > 0) {
        //console.log('TABLE DATA', tableData);
        const teamIndex = Math.max(tableData.findIndex((r) => { var _a, _b; return ((_a = r === null || r === void 0 ? void 0 : r.teamTriCode) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = CONFIG.tricode) === null || _b === void 0 ? void 0 : _b.toLowerCase()); }), 0);
        //console.log('TEAM INDEX', teamIndex);
        let beforeRows, afterRows;
        if (maxRows % 2 === 0) {
            beforeRows = maxRows / 2 - 1;
            afterRows = maxRows / 2;
        }
        else {
            beforeRows = afterRows = (maxRows - 1) / 2;
        }
        let startIndex, endIndex;
        if (teamIndex - beforeRows < 0) {
            startIndex = 0;
            endIndex = Math.min(tableData.length - 1, teamIndex + afterRows - (teamIndex - beforeRows));
        }
        else if (teamIndex + afterRows >= tableData.length) {
            endIndex = tableData.length - 1;
            startIndex = Math.max(0, teamIndex -
                beforeRows -
                (teamIndex + afterRows - (tableData.length - 1)));
        }
        else {
            startIndex = teamIndex - beforeRows;
            endIndex = teamIndex + afterRows;
        }
        filteredTableData = tableData.slice(startIndex, endIndex + 1);
    }
    else if (maxRows > 0) {
        filteredTableData = tableData.slice(0, Math.min(tableData.length, maxRows));
    }
    else {
        filteredTableData = tableData;
    }
}
function handleImageError(event) {
    hideBrokenImgDisplayAltText(event);
}
</script>

<BaseAtom _isotope="StandingsAtom" {...container} {groupHeaderBGColor} _cssVariablePrefix="stands-table" bind:_borderBoxWidthPx={atomWidthPx}>
  <div class="league-table">
    <div class="logo-wrapper">
      <ImageParticle _block={true} {...imgLeagueLogo} {handleImageError}/>
    </div>
    <div class="table-wrapper">
      <table class={`layout-${layout}`}>
        <thead>
          <tr>
            <td class="Position">
              <span>Pos.</span>
            </td>
            <td class="Team">Team</td>
            {#each tableColumns as col}
              {#if col === "GD"}
                <td class={col}>
                  <span class="desktop-show">{col}</span>
                  <span class="mobile-show">+/-</span>
                </td>
              {:else}
                <td class={col}>{col}</td>
              {/if}
            {/each}
          </tr>
        </thead>
        <tbody>
          {#if !hasGroupData}
            {#each filteredTableData as record}
              <tr
                class={(record.recordType || "NONE") +
                  (highlightTeam &&
                  record.teamTriCode &&
                  CONFIG.tricode &&
                  record.teamTriCode.toLowerCase() ===
                    CONFIG.tricode.toLowerCase()
                    ? " highlight-team-row"
                    : "")}
              >
                <td class={"Position " + (record.upDown || "NONE")}
                  >{record.position}</td
                >
                <td class={teamShadowFlag ? "Team-with-shadow" : "Team-without-shadow"}>
                  <div class="team-logo-wrapper">
                    <img src={record.teamLogo} alt="TeamLogo" />
                  </div>
                  <span
                    >{#if layout === "abbr"}{record.teamTriCode}{:else}{record.teamName}{/if}</span
                  >
                  {#if record.isLive}
                    <img
                      style="max-height: 4rem; padding-left: 1rem;"
                      src={CONFIG.liveIndicatorImageUrl}
                    />
                  {/if}
                </td>
                {#each tableColumns as col}
                  <td class={col}>
                    {col !== "GD" || parseInt(record[col]) <= 0
                      ? record[col]
                      : "+" + record[col]}
                  </td>
                {/each}
              </tr>
            {/each}
          {:else}
            {#each groupNames || [] as group}
              <tr class="cup-group-name">
                <td colspan="9" align="left" class="group-name-td">{group}</td>
              </tr>
              {#each groupTableData?.filter((data) => data?.groupName === group) || [] as record}
                <tr
                  class={(record?.recordType || "NONE") +
                    (highlightTeam &&
                    record?.teamTriCode &&
                    CONFIG.tricode &&
                    record?.teamTriCode.toLowerCase() ===
                      CONFIG.tricode.toLowerCase()
                      ? " highlight-team-row"
                      : "")}
                >
                  <td class={"Position " + (record?.upDown || "NONE")}
                    >{record?.groupRank}</td
                  >
                  <td class={teamShadowFlag ? "Team-with-shadow" : "Team-without-shadow"}>
                    <div class="team-logo-wrapper">
                      <img src={record?.teamLogo} alt="TeamLogo" />
                    </div>
                    <span
                      >{#if layout === "abbr"}{record?.teamTriCode}{:else}{record?.teamName}{/if}</span
                    >
                    {#if record?.isLive}
                      <img
                        style="max-height: 4rem; padding-left: 1rem;"
                        src={CONFIG.liveIndicatorImageUrl}
                      />
                    {/if}
                  </td>
                  {#each tableColumns as col}
                    <td class={col}>
                      {col !== "GD" || parseInt(record[col]) <= 0
                        ? record[col]
                        : "+" + record[col]}
                    </td>
                  {/each}
                </tr>
              {/each}
            {/each}
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</BaseAtom>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.league-table {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #f1f1f1;
  background: white;
  width: 100%; }

.logo-wrapper {
  height: 80px;
  padding: 16px 45px; }
  .logo-wrapper img {
    height: 100%; }

.table-wrapper table {
  border-collapse: collapse;
  font-size: 16px;
  width: 100%; }

.table-wrapper tr {
  height: 50px; }

.table-wrapper thead {
  background-color: var(--theme-inverted-background-color, black);
  font-weight: bold;
  color: var(--theme-inverted-foreground-color, white); }
  .table-wrapper thead td {
    border: 1px solid var(--theme-inverted-background-color, black);
    text-align: center; }
    .table-wrapper thead td * {
      color: var(--theme-inverted-foreground-color, white); }

.table-wrapper tbody {
  color: var(--theme-primary-foreground-color, black); }
  .table-wrapper tbody tr {
    position: relative;
    -webkit-transform: translateZ(0); }
    .table-wrapper tbody tr::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 50px;
      background: #53d975; }
    .table-wrapper tbody tr:nth-child(even) {
      background: #f1f1f1; }
    .table-wrapper tbody tr.GREEN::after {
      background: #53d975; }
    .table-wrapper tbody tr.YELLOW::after {
      background: #f5c800; }
    .table-wrapper tbody tr.RED::after {
      background: #e3424f; }
    .table-wrapper tbody tr.NONE::after {
      display: none; }
  .table-wrapper tbody td {
    text-align: center;
    border: none; }
  .table-wrapper tbody .Position {
    position: relative; }
    .table-wrapper tbody .Position::before {
      content: "";
      border: 5px solid rgba(0,0,0,0);
      position: absolute;
      left: 19px;
      top: 15px;
      width: 0px;
      height: 0px;
      display: block;
      transform: scale(1, 1.5); }
    .table-wrapper tbody .Position.UP::before {
      top: 20px;
      left: 10px;
      border-bottom-color: #53d975; }
    .table-wrapper tbody .Position.DOWN::before {
      top: 30px;
      left: 10px;
      border-top-color: #e3424f; }
    .table-wrapper tbody .Position.NONE::before {
      display: none; }
  .table-wrapper tbody .Team-without-shadow {
    display: flex;
    align-items: center;
    height: 50px;
    border-left: none;
    border-right: none; }
    .table-wrapper tbody .Team-without-shadow .team-logo-wrapper {
      width: auto;
      height: 40px; }
      .table-wrapper tbody .Team-without-shadow .team-logo-wrapper img {
        height: 100%;
        display: block; }
    .table-wrapper tbody .Team-without-shadow span {
      display: block;
      padding-right: 10px;
      padding-left: 5px;
      white-space: nowrap; }

.table-wrapper .Position {
  width: 50px;
  text-align: center;
  padding: 0 0 0 26px; }

.table-wrapper .Team {
  padding: 0 0 0 26px;
  text-align: left; }

.table-wrapper .PLD,
.table-wrapper .W,
.table-wrapper .D,
.table-wrapper .L,
.table-wrapper .GF,
.table-wrapper .GA,
.table-wrapper .GD,
.table-wrapper .PTS {
  width: 90px; }

table.layout-abbr .Position {
  width: 55px; }

tbody > tr.highlight-team-row {
  background-color: var(--theme-inverted-background-color, black) !important;
  color: var(--theme-inverted-foreground-color, white) !important; }

.mobile-show {
  display: none; }

@media (max-width: 1023px) {
  .logo-wrapper {
    height: 36px;
    padding: 9px 22px; }
  .table-wrapper table {
    font-size: 12px; }
  .table-wrapper tr {
    height: 40px; }
  .table-wrapper thead {
    background-color: var(--theme-inverted-background-color, black); }
    .table-wrapper thead td {
      border: 1px solid rgba(1,13,44,0);
      text-align: center; }
      .table-wrapper thead td.Team {
        padding-left: 2px; }
  .table-wrapper tbody tr::after {
    width: 4px; }
  .table-wrapper tbody .Position::before {
    content: "";
    border: 3px solid rgba(0,0,0,0);
    left: 11px;
    top: 5px; }
  .table-wrapper tbody .Position.UP::before {
    top: 15px; }
  .table-wrapper tbody .Position.DOWN::before {
    top: 20px; }
  .table-wrapper tbody .Team {
    display: flex;
    align-items: center;
    height: 40px;
    border-left: none;
    position: relative; }
    .table-wrapper tbody .Team:after {
      box-shadow: 10px 0 15px -15px inset;
      content: " ";
      height: 32px;
      position: absolute;
      top: 0;
      right: -5px;
      width: 15px; }
    .table-wrapper tbody .Team .team-logo-wrapper {
      width: auto;
      height: 19px; }
      .table-wrapper tbody .Team .team-logo-wrapper img {
        height: 100%; }
  .table-wrapper tbody .Team-without-shadow {
    display: flex;
    align-items: center;
    height: 40px;
    border-left: none;
    position: relative; }
    .table-wrapper tbody .Team-without-shadow:after {
      content: " ";
      height: 32px;
      position: absolute;
      top: 0;
      right: -5px;
      width: 15px; }
    .table-wrapper tbody .Team-without-shadow .team-logo-wrapper {
      width: auto;
      height: 19px; }
      .table-wrapper tbody .Team-without-shadow .team-logo-wrapper img {
        height: 100%; }
  .table-wrapper .Position {
    padding: 0 0 0 21px;
    width: 60px; }
  .table-wrapper .Team {
    padding: 0 0 0 0%; }
  .table-wrapper thead .Team {
    padding: 0; }
  .table-wrapper .PLD,
  .table-wrapper .W,
  .table-wrapper .D,
  .table-wrapper .L,
  .table-wrapper .GF,
  .table-wrapper .GA,
  .table-wrapper .GD,
  .table-wrapper .PTS {
    width: 32px; }
  .desktop-show {
    display: none; }
  .mobile-show {
    display: inline; } }

.cup-group-name {
  background-color: var(--stands-table-group-header-bg-color, #595959) !important;
  color: #FFF !important; }
  .cup-group-name::after {
    background: unset !important; }

.group-name-td {
  text-align: left !important;
  padding-left: 1rem;
  font-weight: 700; }</style>
