<F7App params={ f7params } >
  <div class="app-wrapper">
    <View name="header" class="app-header" url="/header" linksView=".view.view-main" />
    <div class="foo">
      <div class="app-main-content" bind:this={appMainContent}>
        <View main class="safe-areas" url="/" animate={false} swipeBack={false} pushState={!Device.cordova} pushStateSeparator="" pushStateRoot={new URL(window.location.href).origin} />
        <View name="footer" class="app-footer" url="/footer" linksView=".view.view-main" />
      </div>
    </div>
  </div>
  <div class="popup-backdrop cards-modal-popup-backdrop" on:click={handleModalBackdropClick} />
</F7App>

<YCFonts />

<style>
  .app-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
  }

  .app-wrapper > :global(.app-header) {
    min-height: 177.234px;
  }
  @media (max-width: 1440px) {
    .app-wrapper > :global(.app-header) {
      min-height: 155.188px;
    }
  }
  @media (max-width: 1024px) {
    .app-wrapper > :global(.app-header) {
      min-height: 133.172px;
    }
  }
  @media (max-width: 599px) {
    .app-wrapper > :global(.app-header) {
      min-height: 61.9453px;
    }
  }

  .app-wrapper > .foo {
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
  }

  .app-wrapper > .foo > .app-main-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    will-change: scroll-position;
  }

  .app-wrapper > .app-main-content > :global(*) {
    flex: 1 0 max-content;
  }

  .app-wrapper > :global(.app-header),
  .app-wrapper > .app-main-content > :global(.app-footer) {
    flex: 0 0 max-content;
  }

  .app-wrapper :global(.view) {
    width: 100% !important;
    overflow: visible !important;
  }

  .app-wrapper :global(.safe-areas) {
    position: static !important;
  }

  .app-wrapper :global(.navbar-hidden) {
    display: none !important;
  }

  .app-wrapper :global(.page-content) {
    overflow: visible !important;
    position: static !important;
  }

  .app-wrapper :global(.view) {
    height: auto !important;
  }

  .app-wrapper :global(.page) {
    height: auto !important;
  }

  .app-wrapper :global(.page-content) {
    height: auto !important;
  }

  .app-wrapper :global(.cards-page-top-flex) {
    height: auto !important;
  }

  .app-wrapper :global(.cards-page-left-flex) {
    height: auto !important;
  }

  .app-wrapper :global(.cards-page-content-area) {
    height: auto !important;
  }

  .app-wrapper :global(.page) {
    position: static !important;
  }

  .app-wrapper :global(.cards-page-left-flex) {
    flex: unset !important;
  }

  .app-wrapper :global(.cards-page-content-area) {
    overflow-y: hidden !important;
  }

  /*
  .app-wrapper > :global(.app-header),
  .app-wrapper > .app-main-content > :global(.app-footer) {
    flex: 0 0 max-content;
  }

  .app-wrapper > :global(.app-header) :global(.view),
  .app-wrapper > .app-main-content > :global(.app-footer) :global(.view) {
    width: 100% !important;
    overflow: visible !important;
  }

  .app-wrapper > :global(.app-header) :global(:is(.view, .page, .page-content, .cards-page-top-flex, .cards-page-left-flex, .cards-page-content-area)),
  .app-wrapper > .app-main-content > :global(.app-footer) :global(:is(.view, .page, .page-content, .cards-page-top-flex, .cards-page-left-flex, .cards-page-content-area)) {
    height: auto !important;
  }

  .app-wrapper > :global(.app-header) :global(.page),
  .app-wrapper > .app-main-content > :global(.app-footer) :global(.page) {
    position: static;
  }

  .app-wrapper > :global(.app-header) :global(.cards-page-left-flex),
  .app-wrapper > .app-main-content > :global(.app-footer) :global(.cards-page-left-flex) {
    flex: unset !important;
  }

  .app-wrapper > :global(.app-header) :global(.cards-page-content-area),
  .app-wrapper > .app-main-content > :global(.app-footer) :global(.cards-page-content-area) {
    overflow-y: hidden !important;
  }
  */</style>

<script lang="ts">var _a;
import { onMount } from 'svelte';
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import { f7, f7ready, App as F7App, View } from 'framework7-svelte';
import cordovaApp from '../js/cordova-app';
import routes, { allViewsParams } from '../js/routes';
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { JanusPushManagerToken } from '../js/push';
import { JanusInternationalizationManagerToken } from '../js/i18n';
import { resolveUrl } from '../js/url';
import { currentRoute } from '../js/routes';
import { windowMetrics as wm } from '../js/stores.js';
import YCFonts from './YCFonts.svelte';
const pushManager = getToken(ROOT, JanusPushManagerToken);
const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
const currentLang = i18nManager.getCurrentLanguageStore();
$: if ((_a = $wm === null || $wm === void 0 ? void 0 : $wm.breakpoint) === null || _a === void 0 ? void 0 : _a.remScale) {
    document.documentElement.style.fontSize = $wm.breakpoint.remScale;
}
// init internationalization early
i18nManager.init();
// Framework7 Parameters
let f7params = {
    id: 'com.yinzcam', // App bundle ID
    name: 'YinzCam', // App name
    theme: 'aurora', // Automatic theme detection
    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: Device.cordova ? {} : {
        path: '/service-worker.js',
    },
    // Input settings
    input: {
        scrollIntoViewOnFocus: Device.cordova && !Device.electron,
        scrollIntoViewCentered: Device.cordova && !Device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
    },
    /*
    panels3d: {
      enabled: true,
    },
    panel: {
        swipe: 'right',
    },
    */
    view: {
        animate: false,
    },
    navbar: {
        iosCenterTitle: true,
        mdCenterTitle: true,
        auroraCenterTitle: true,
    },
    stackPages: true
};
let appMainContent;
function handleModalBackdropClick() {
    // handles back when clicking outside of routable modals of cards lists.
    const mainViewHasContent = !!f7.view.main.el.querySelector('div.page.page-current');
    //console.log('VIEW MAIN', f7.view.main, mainViewHasContent);
    if (mainViewHasContent) {
        // effectively dismiss modal
        f7.view.main.router.back();
    }
    else {
        resolveUrl('home').func();
    }
}
onMount(() => {
    f7ready(async () => {
        // Init cordova APIs (see cordova-app.js)      
        if (Device.cordova) {
            cordovaApp.init(f7);
        }
        pushManager.handleAppLaunch();
        f7.view.main.router.on('routeChange', (newRoute) => {
            //console.log('ROUTE CHANGE', newRoute);
            currentRoute.set(newRoute);
            allViewsParams.set({});
            if (appMainContent) {
                appMainContent.scrollTop = 0;
            }
        });
        let lastLang = null;
        currentLang.subscribe((lang) => {
            try {
                //console.log('CURRENTLANG IN YCAPP', lang, f7.view);
                if (!lastLang) {
                    lastLang = lang;
                }
                else if (lastLang !== lang) {
                    /*
                    for (let router of [f7.view.header.router, f7.view.footer.router]) {
                      router.navigate(router.currentRoute.url + '?nocache=1', {
                        ignoreCache: true,
                      });
                    }
                    */
                    lastLang = lang;
                }
            }
            catch (e) {
                console.log('caught exception in ycapp language change handler', e);
            }
        });
    });
});
</script>
