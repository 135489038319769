import {
  YinzCamCardsServiceBorder,
  YinzCamCardsServiceBackground,
  YinzCamCardsServicePadding,
  YinzCamCardsServiceMargin,
  YinzCamCardsServiceElement,
  YinzCamCardsServiceConditions,
  YinzCamCardsServiceFont,
  YinzCamCardsServiceBoxShadow,
  YinzCamCardsServicePage
} from 'yinzcam-cards'
import _ from 'lodash'
import { CardsDataSourceRegistration } from '../common/CardsDataSourceRegistration'
import { Readable, writable, derived, Writable, get } from 'svelte/store'
import ROOT from '../../../inversify.config';
import { getToken } from 'inversify-token';
import { JanusAnalyticsManagerToken } from '../../../js/analytics';
import { v4 as uuid4 } from "uuid";
import { Device } from "framework7";
import { windowMetrics } from "../../../js/stores.js";
import { JanusModeContextManagerToken } from "../../../js/mode";
import { JanusSignonManagerToken } from '../../../js/sso';
import { DateTime, Duration } from "luxon";
import type { LocaleOptions, ToHumanDurationOptions, ToRelativeOptions } from "luxon";
import { resolveUrl } from 'js/url';
import fitty, { FittyInstance } from 'fitty';
import { CardsEditorComponentOptions } from '../common/CardsEditorInterfaces';
import { Item } from 'svelte-dnd-action';
import { t } from "js/i18n";
import { CardsDataSourceManager, CardsDataSourceStatus } from '../common/CardsDataSourceManager';


const CONTENTFUL_ASSETS_CDN_DOMAINS = ['images.ctfassets.net', 'videos.ctfassets.net', 'downloads.ctfassets.net', 'assets.ctfassets.net'];
const YC_CONTENTFUL_ASSETS_CDN_DOMAIN = 'contentful-assets.yinzcam.com';
const CLOUDINARY_ENABLED_DOMAINS = [YC_CONTENTFUL_ASSETS_CDN_DOMAIN, 'cmscdnus.yinzcam.com', 'resources-uk.yinzcam.com', 'premierleague-static-files-cdn.yinzcam.com'];


const signonManager = getToken(ROOT, JanusSignonManagerToken)
const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const langStore = contextManager.getLanguageComponent().store;
let currentLang = CONFIG.defaultLanguage;
langStore.subscribe((lang: string) => {
  if (lang) {
    currentLang = lang;
  }
});
let $t = get(t);
t.subscribe((newT) => {
  if (newT) {
    $t = newT;
  }
});

export function promiseWithResolvers<T>() {
  let resolve, reject;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return { promise, resolve, reject };
}

export function generateSequenceId(prefix: string, parentSequenceId: string, childSequenceNumber: number) {
  return parentSequenceId + '-' + prefix + _.padStart(childSequenceNumber.toString(), CONFIG.cardsSequenceNumberDigits, '0');
}

export function getEditorDisplayName(editorOptions: CardsEditorComponentOptions<Item>) {
  if (!editorOptions) {
    return '';
  }
  let displayName = editorOptions.componentTypeName;
  if (editorOptions.component.displayName) {
    displayName = `${editorOptions.component.displayName} (${displayName})`;
  } else {
    let offset = (editorOptions.componentTypeName === 'View') ? CONFIG.cardsSequenceNumberDigits + 3 + 1 : 0;
    let index = parseInt(editorOptions.sequenceId.slice(-CONFIG.cardsSequenceNumberDigits - offset));
    //console.log("INDEX INDEX INDEX = ", index, editorOptions.cloned);
    if (!isNaN(index)) {
      displayName = `${displayName} ${index + 1}`;
    }
  }
  return displayName;
}

export function getEditorIconURL(options: CardsEditorComponentOptions<Item>) {
  return `https://static.yinzcam.com/images/disco/layer_${options?.componentTypeName?.toLowerCase()}.svg`;
}

export function buildCssPadding(
  padding: YinzCamCardsServicePadding,
  defaultPadding?: YinzCamCardsServicePadding
) {
  let css: string = '';
  for (const dir of ['top', 'right', 'bottom', 'left']) {
    const val = padding?.[dir] || defaultPadding?.[dir];
    if (val) {
      css += ` padding-${dir}: ${val};`;
    }
  }
  return css.trim();
}

export function buildCssMargin(
  margin: YinzCamCardsServiceMargin,
  defaultMargin?: YinzCamCardsServiceMargin
) {
  let css: string = '';
  for (const dir of ['top', 'right', 'bottom', 'left']) {
    const val = margin?.[dir] || defaultMargin?.[dir];
    if (val) {
      css += ` margin-${dir}: ${val};`;
    }
  }
  return css.trim();
}

export function buildCssBorder(
  border: YinzCamCardsServiceBorder,
  defaultBorder?: YinzCamCardsServiceBorder
) {
  let css: string = '';
  for (const dir of ['top', 'right', 'bottom', 'left', 'radius']) {
    const val = border?.[dir] || defaultBorder?.[dir];
    if (val) {
      css += ` border-${dir}: ${val};`;
    }
  }
  return css.trim();
}

export function buildCssBoxShadow(
  boxShadow: YinzCamCardsServiceBoxShadow,
  defaultBoxShadow?: YinzCamCardsServiceBoxShadow
) {
  let css: string = '';
  if (!boxShadow) {
    boxShadow = defaultBoxShadow;
    if (!boxShadow) {
      return css;
    }
  }
  css = `box-shadow: ${boxShadow.offsetX || '0px'} ${boxShadow.offsetY || '0px'} ${boxShadow.blurRadius || '0px'} ${boxShadow.spreadRadius || '0px'} ${boxShadow.color || 'transparent'};`;
  //console.log('buildCssBoxShadow', css);
  return css.trim();
}

export function buildCssBackground(background: YinzCamCardsServiceBackground, defaultBackground?: YinzCamCardsServiceBackground) {
  let css: string = '';
  // backgrounds are strange because the different properties interact with each other
  // we use the background or the default background wholesale in this case
  if (!background) {
    background = defaultBackground;
    if (!background) {
      return css;
    }
  }
  //console.log('BACKGROUND', background);
  if (background.url) {
    css += ` background-image: url('${background.url}');`;
  }
  for (const key of ['color', 'image', 'size', 'attachment', 'position', 'repeat']) {
    let val = background[key];
    if (val) {
      if (key === 'size') {
        switch (val) {
          case "AUTO_AUTO":
            val = 'auto auto';
            break;
          case "100_AUTO":
            val = '100% auto';
            break;
          case "AUTO_100":
            val = 'auto 100%';
            break;
          case "100_100":
            val = '100% 100%';
            break;
          default:
            // pass through val unchanged
            break;
        }
      }
      css += ` background-${key}: ${val.toLowerCase()};`;
    }
  }
  return css.trim();
}

export function buildCssFont(font: YinzCamCardsServiceFont, defaultFont?: YinzCamCardsServiceFont) {
  let css: string = '';
  if (!font) {
    font = defaultFont;
    if (!font) {
      return css;
    }
  }
  if (font.color) {
    css += ` color: ${font.color.toLowerCase()};`;
  }
  for (const key of ['size', 'family', 'weight', 'style', 'variant', 'kerning']) {
    const val = font[key];
    if (val) {
      css += ` font-${key}: ${val.toLowerCase()};`;
    }
  }
  return css.trim();
}

export function buildCssScrollContainer(height?: string) {
  if (!height) {
    return "";
  }
  return `
    height: ${height};
    overflow-y: auto;
    position: relative;`;
}

export interface ExpandRepeatsInfo {
  cloned: boolean
  sourceId?: string
  originalId?: string
  order?: number
}

export interface RepeatingObject {
  responsiveness: any;
  id: string
  repeat?: number
  __expandRepeats?: ExpandRepeatsInfo
}

export function expandTemplateParams(
  template?: string,
  params?: { [key: string]: string }
) {
  try {
    if (
      !template ||
      typeof template !== 'string' ||
      !params ||
      !template.includes('{{')
    ) {
      return template
    }
    return _.template(template, { interpolate: /{{([\s\S]+?)}}/g })(params)
  } catch (e) {
    return template;
  }
}

export function expandTemplateParamsRecursive(obj?: any, params?: { [key: string]: string }) {
  try {
    if (_.isString(obj)) {
      return expandTemplateParams(obj, params);
    } else if (_.isObjectLike(obj)) {
      let expandedObject = _.clone(obj);
      for (const [key, value] of Object.entries(obj)) {
        expandedObject[key] = expandTemplateParamsRecursive(value, params);
      }
      return expandedObject;
    } else {
      return obj;
    }
  } catch (e) {
    return obj;
  }
}

export function expandRepeats<T extends RepeatingObject>(objects?: T[], prefix: string = "", $wm: any = null): T[] {
  let expanded: T[] = [];
  if (!objects) {
    return expanded;
  }
  for (let object of objects) {
    if (object.__expandRepeats?.cloned) {
      continue;
    }
    let repeat = object.repeat || 1;
    if ($wm) {
      const responsiveConfiguration = object?.responsiveness
        ?.filter((i) => i.maxWidth >= 0)
        ?.sort((a, b) => a.maxWidth - b.maxWidth)
        ?.find((i) => i.maxWidth >= $wm.width);
      if (responsiveConfiguration) {
        for (const k in responsiveConfiguration) {
          if (k !== 'repeat') {
            continue;
          }
          const val = responsiveConfiguration[k];
          if (!_.isNil(val)) {
            repeat = (object['__additionalRepeats'] || 0) + val;
          }
        }
      }
    }
    for (let i = 0; i < repeat; i++) {
      //let clone = _.clone(object)
      const tag = `-${prefix}${i}`;
      //const er: ExpandRepeatsInfo = clone.__expandRepeats = { cloned: false, originalId: clone.id };
      let clone;
      if (i == 0) {
        clone = _.clone(object);
        clone.__expandRepeats = { cloned: false, originalId: clone.id };
      } else {
        clone = _.cloneDeepWith(object, (v, k, o, s) => {
          //console.log("CLONEDEEP STACK", o, s);
          if (k === 'id' && _.isString(v)) {
            const layerTag = (s) ? `-L${s.size}` : '';
            return `${v}_C${layerTag}${tag}`;
          }
          if (k === '__expandRepeats') {
            return null;
          }
        });
        clone.__expandRepeats = {
          cloned: true,
          originalId: clone.id,
          sourceId: object.id,
          order: i - 1
        };
        delete clone.repeat;
      }
      /*
      for (let key in clone) {
        // TODO: Probably a way to make this type safe.
        let val: any = clone[key];
        if (Array.isArray(val) && val.length > 0 && typeof val[0] === 'object' && val[0] !== null) {
          clone[key] = expandRepeats(val, `${tag}-`, repeatAdd) as any;
        }
      }
      */
      expanded.push(clone)
    }
  }
  return expanded
}

export function findSourceStore(
  sources: CardsDataSourceRegistration[],
  clazz: string,
  tags?: string[]
): Readable<any> {
  if (!sources) {
    return null
  }
  let reg = sources.find((source) => source?.spec?.class === clazz)
  return reg ? reg.store : null
}

export function getFirstSourceStore(
  sources: CardsDataSourceRegistration[],
  tags?: string[]
): Readable<any> {
  if (!sources) {
    return null
  }
  let reg = sources.find((source) => !!source.spec)
  return reg ? reg.store : null
}

export function getFirstSourceRegistration(
  sources: CardsDataSourceRegistration[],
  tags?: string[]
): CardsDataSourceRegistration {
  if (!sources) {
    return null
  }
  return sources.find((source) => !!source.spec);
}

export function getHrefForMediaItem(
  type?: string,
  id?: string,
  slug?: string
): string {
  if (!type || (!id && !slug)) {
    return '#'
  }
  let suffix = ''
  if (slug) {
    suffix = `/${encodeURIComponent(slug)}`
  } else {
    suffix = `?mediaId=${encodeURIComponent(id)}`
  }
  let ret = '#';
  switch (type.toUpperCase()) {
    case 'N':
    case 'B':
      ret = `NewsReader${suffix}`
      break;
    case 'V':
    case 'Y':
      ret = `VideoPlayer${suffix}`
      break;
    case 'G':
      ret = `PhotoViewer${suffix}`
      break;
    case 'A':
      ret = `AudioPlayer${suffix}`
      break;
    default:
      ret = '#'
      break;
  }
  return ret;
}

export function getHrefForMatchCenter(gameId: string, slug?: string): string {
  if (!gameId && !slug) {
    return '#'
  }
  let suffix = ''
  if (slug) {
    suffix = `/${encodeURIComponent(slug)}`
  } else {
    suffix = `?gameId=${encodeURIComponent(gameId)}`
  }
  return `MatchCenter${suffix}`
}

export function getTeamLogoFallback(league?: string) {
  league = league || CONFIG.league
  return `https://resources-us.yinzcam.com/${league.toLowerCase()}/shared/logos/${league.toUpperCase()}_placeholder.png`
}

export function getTeamLogoFromLeagueAndTricode(
  league: string,
  tricode: string
) {
  // TODO: make this work for other leagues
  // https://resources-us.yinzcam.com/csf/shared/logos/CSF_ADC.png
  if (!league || !tricode) {
    return getTeamLogoFallback(league)
  }

  if (league.toLowerCase() === 'esp') {
    league = 'lfp'
  }

  return `https://resourceslfp.azureedge.net/${league.toLowerCase()}/shared/logos/esp_${tricode.toLowerCase()}_dark.png`
}

export function getTeamLogoFromTricode(tricode: string) {
  return getTeamLogoFromLeagueAndTricode(CONFIG.league, tricode)
}

export function getTeamLogoFromLogoId(logoId: string) {
  let leagueTricode = (logoId || '').split('_')
  if (!Array.isArray(leagueTricode) || leagueTricode.length < 2) {
    return getTeamLogoFallback()
  }
  return getTeamLogoFromLeagueAndTricode(leagueTricode[0], leagueTricode[1])
}

export function getTeamLogoFromTeamObject(obj: any) {
  if (!obj || !obj._attributes) {
    return getTeamLogoFallback()
  }
  if (obj._attributes.LogoId) {
    return getTeamLogoFromLogoId(obj._attributes.LogoId)
  }
  if (obj._attributes.TriCode) {
    return getTeamLogoFromTricode(obj._attributes.TriCode)
  }
  return getTeamLogoFallback()
}

export function createAnalyticsPageContext(analyticsData?: { path?: string, name?: string, content?: string }, params?: { [key: string]: string }) {
  const data = expandTemplateParamsRecursive(analyticsData, params);
  const args = [data?.path, data?.name, data?.content];
  return getToken(ROOT, JanusAnalyticsManagerToken).createPageContext(...args);
}

export function getTemplatedElementDataFromSource(element: Partial<YinzCamCardsServiceElement>, dataFieldName: string, source: any) {
  //console.log('getTemplatedElementDataFromSource', ...arguments);
  try {
    const dataFieldValue = _.get(element?.data, dataFieldName);

    // If the element contains a value for this field and it looks like a template, then resolve the template
    if (_.isString(dataFieldValue) && dataFieldValue.includes('<%')) {
      // If we don't have a source, we have no hope of resolving the template.
      if (!_.isObjectLike(source)) {
        return undefined;
      }
      const ret = _.template(dataFieldValue, { 'variable': 'source' })(source);
      //console.log('getTemplatedElementDataFromSource', dataFieldName, dataFieldValue, source, ret);
      return ret;
    }

    // If the data field is in the source then take its value directly.
    if (_.isObjectLike(source) && _.has(source, dataFieldName)) {
      return _.get(source, dataFieldName);
    }

    // Otherwise, return the data field value as-is (or undefined).
    return dataFieldValue;
  } catch (e) {
    console.error("getTemplatedElementDataFromSource: failed to expand templated value", e);
    return undefined;
  }
}

// https://stackoverflow.com/a/67551175
export function blobToDataUri(blob: Blob): Promise<string> {
  //console.log('BLOB TO DATA URI', blob);
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = _e => resolve(reader.result as string);
    reader.onerror = _e => reject(reader.error);
    reader.onabort = _e => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });

}

export function getConditionsCheckStore(conditionsStore: Readable<YinzCamCardsServiceConditions> | Readable<{ conditions: YinzCamCardsServiceConditions }>, mergedParamsStore: Readable<Record<string, string>>, dsm: CardsDataSourceManager, conditionSourceId?: string): Readable<boolean> {
  if (!conditionsStore || !windowMetrics) {
    return writable(false);
  }

  let dataSourceConditionStore: Readable<CardsDataSourceStatus> = (conditionSourceId) ? dsm.getSourceStatusStore(conditionSourceId) : writable(null);

  let signOnStore = signonManager.getStatusComponent().store;
  return derived([conditionsStore, windowMetrics as Readable<any>, langStore, signOnStore, dataSourceConditionStore, mergedParamsStore], ([$conditionsOrContainer, $wm, $lang, $signOnStore, $dataSourceConditionStore, $mergedParams]) => {
    let conditionsMet: boolean = true;
    if (!$conditionsOrContainer) {
      return conditionsMet;
    }
    let $conditions: YinzCamCardsServiceConditions;
    if ("conditions" in $conditionsOrContainer) {
      $conditions = $conditionsOrContainer.conditions;
    } else {
      $conditions = $conditionsOrContainer;
    }
    if (!$conditions) {
      return conditionsMet;
    }
    if (!_.isNil($conditions.isNative)) {
      conditionsMet &&= $conditions.isNative === Device.cordova;
    }
    if (!_.isNil($conditions.isDesktop)) {
      conditionsMet &&= $wm && $conditions.isDesktop === $wm.isDesktop;
    }
    if (!_.isNil($conditions.language)) {
      conditionsMet &&= $conditions.language === $lang;
    }
    if ($conditions.loggedIn) {
      conditionsMet &&= $conditions.loggedIn === $signOnStore.loggedIn;
    }
    if ($dataSourceConditionStore && $conditions.dataSource?.sourceId && $conditions.dataSource.sourceId === $dataSourceConditionStore.sourceId) {
      if (!_.isNil($conditions.dataSource.hasData)) {
        conditionsMet &&= $conditions.dataSource.hasData === $dataSourceConditionStore.hasData;
      }
      if (!_.isNil($conditions.dataSource.dataFilled)) {
        conditionsMet &&= $conditions.dataSource.dataFilled === $dataSourceConditionStore.dataFilled;
      }
    }
    if ($mergedParams && $conditions.param?.key) {
      if (!_.isNil($conditions.param.value)) {
        conditionsMet &&= $conditions.param.value === ('' + $mergedParams[$conditions.param.key]);
      } else if (!_.isNil($conditions.param.hasValue)) {
        conditionsMet &&= $conditions.param.hasValue === !!$mergedParams[$conditions.param.key];
      }
    }
    if ($conditions.not) {
      conditionsMet = !conditionsMet;
    }
    //console.log ('conditionMet: ', conditionsMet);
    return conditionsMet;
  });
}

export function getOmittedTitle(title: any, length: number = 57) {
  if (!title) {
    return title;
  }

  const str = String(title);
  return str.length > length ? str.substring(0, length) + " ..." : (str || '');
}

// This function expects its args to be alternating pairs of [string, any, string, any, string, any, ...]
export function buildInlineStyle(...args: (string | bigint | number | boolean)[]): string {
  let css: string = '';
  for (let i = 0; i < args.length; i += 2) {
    const value = args[i + 1];
    //console.log('ARGS', args[i], value);
    if (_.isNil(value)) {
      continue;
    }
    const varName = args[i];
    if (!_.isString(varName)) {
      console.warn(`cannot add inline style variable ${varName} because the name is not a string`);
    } else if (varName !== _.escape(varName)) {
      console.warn(`cannot add inline style variable ${varName} because it contains HTML special characters`);
    } else if (varName.endsWith('-image')) {
      css += `--${varName}: url('${_.escape(String(value))}'); `;
    } else {
      css += `--${varName}: ${_.escape(String(value))}; `;
    }
  }
  return css.trimEnd();
}

export function buildInlineStyleFromObject(obj: (object | string | bigint | number | boolean), cssVariablePrefix?: string): string {
  if (_.isNil(obj)) {
    return "";
  }
  if (!_.isPlainObject(obj)) {
    return (cssVariablePrefix) ? `--${cssVariablePrefix}: ${_.escape(String(obj))};` : '';
  }
  let entries = Object.entries(obj);
  entries = entries.map(e => {
    e[0] = _.kebabCase(e[0]);
    return e;
  });
  if (cssVariablePrefix) {
    entries = entries.map(e => {
      e[0] = `${cssVariablePrefix}-${e[0]}`;
      return e;
    });
  }
  return buildInlineStyle(...entries.flat());
}

export function buildInlineStyleFromObjects(...objs: ((object | string | bigint | number | boolean) | [(object | string | bigint | number | boolean), string])[]) {
  return mergeInlineStyles(...objs.map((obj) =>
    (_.isArray(obj)) ?
      buildInlineStyleFromObject(obj[0], obj[1])
      : buildInlineStyleFromObject(obj)
  ));
}

export function buildThemeModeInlineStyle(mode: string) {
  if (!mode || !Object.values(CONFIG.themeModes).includes(mode)) {
    return '';
  }
  return Object.values(CONFIG.themeModeAttributes).map((attr) => `--theme-mode-${attr}: var(--theme-${mode}-${attr});`).join(' ');
}

export function buildClearThemeModeInlineStyle() {
  return Object.values(CONFIG.themeModeAttributes).map((attr) => `--theme-mode-${attr}: initial;`).join(' ');
}

export function mergeInlineStyles(...styles: string[]) {
  return styles
    .map((s) => s?.trim())
    .filter((s) => s?.length > 0)
    .join(' ');
}

export function formatNumber(num: number, options?: Intl.NumberFormatOptions): string {
  if (!_.isNumber(num)) {
    return "";
  }
  try {
    return num.toLocaleString(navigator.language, options);
  } catch {
    console.warn('unable to format number', num, options)
  }
  return "";
}

export function formatDateTimeRelative(isoDateTime: string, options?: ToRelativeOptions): string {
  if (!isoDateTime) {
    return isoDateTime;
  }
  try {
    return DateTime.fromISO(isoDateTime).setLocale(navigator.language).toRelative();
  } catch {
    console.warn('unable to format relative date time', isoDateTime)
  }
  return isoDateTime;
}

export function formatDateTimeLocal(isoDateTime: string, formatOpts?: Intl.DateTimeFormatOptions, localeOpts?: LocaleOptions): string {
  if (!isoDateTime) {
    return isoDateTime;
  }
  try {
    return DateTime.fromISO(isoDateTime).toLocal().setLocale(navigator.language).toLocaleString(formatOpts, localeOpts);
  } catch {
    console.warn('unable to format local date time', isoDateTime)
  }
  return isoDateTime;
}

export function formatDuration(isoDuration: string, opts?: ToHumanDurationOptions) {
  if (!isoDuration) {
    return isoDuration;
  }
  try {
    return Duration.fromISO(isoDuration).toHuman(opts);
  } catch {
    console.warn('unable to format duration', isoDuration)
  }
  return isoDuration;
}

export function translateString(string, t) {
  if (!string) return string;
  try {
    return string?.split(" ")?.map(word => {
      if (typeof word === 'string') {
        if (!isNaN(Number(word))) {
          return word;
        }
        return t(`${word?.charAt(0).toUpperCase() + word.slice(1)} Read`);
      }
      return word;
    })?.join(" ");
  } catch {
    console.warn("Not a proper string !");
  }
}

export function imageAspectRatioAction(img: HTMLImageElement, $aspectRatio: Writable<string>) {
  function update($newAspectRatio: Writable<string>) {
    $newAspectRatio.set(get($aspectRatio));
    $aspectRatio = $newAspectRatio;
  }

  function writeAspectRatio() {
    if (img.complete) {
      $aspectRatio.set(`${img.naturalWidth}/${img.naturalHeight}`);
    }
  }

  img.addEventListener('load', writeAspectRatio);
  writeAspectRatio();

  return { update };
}

export function videoAspectRatioAction(video: HTMLVideoElement, $aspectRatio: Writable<string>) {
  function update($newAspectRatio: Writable<string>) {
    $newAspectRatio.set(get($aspectRatio));
    $aspectRatio = $newAspectRatio;
  }

  function writeAspectRatio() {
    if (video.videoWidth > 0 && video.videoHeight > 0) {
      $aspectRatio.set(`${video.videoWidth}/${video.videoHeight}`);
    }
  }

  video.addEventListener('loadedmetadata', writeAspectRatio);
  writeAspectRatio();

  return { update };
}

export function addClassOnHoverAction(el: HTMLElement, cls: string) {
  let isHovering = false;

  function update(newCls: string) {
    if (isHovering) {
      el.classList.remove(cls);
      el.classList.add(newCls);
    }
    cls = newCls;
  }

  el.addEventListener('mouseenter', (e) => {
    isHovering = true;
    el.classList.add(cls);
  });
  el.addEventListener('mouseleave', (e) => {
    isHovering = false;
    el.classList.remove(cls);
  });

  return { update };
}

export function setStoreOnHoverAction(el: HTMLElement, $hovering: Writable<boolean>) {
  let isHovering = false;

  function update($newHovering: Writable<boolean>) {
    $newHovering.set(isHovering)
    $hovering = $newHovering;
  }

  el.addEventListener('mouseenter', (e) => {
    $hovering.set(isHovering = true);
  });
  el.addEventListener('mouseleave', (e) => {
    $hovering.set(isHovering = false);
  });

  return { update };
}

export type AddClassOnClickActionOptions = { cls: string, childSelector?: string, removeClassFromSiblings?: boolean };
export function addClassOnClickAction(el: HTMLElement, { cls, childSelector, removeClassFromSiblings }: AddClassOnClickActionOptions) {
  let targetEl: HTMLElement = null;

  function isClicked() {
    return el.classList.contains(cls);
  }

  function clickHandler(e: PointerEvent) {
    if (isClicked()) {
      el.classList.remove(cls);
    } else {
      if (removeClassFromSiblings) {
        const siblings = el.parentElement.children;
        for (const sibling of siblings) {
          sibling.classList.remove(cls);
        }
      }
      el.classList.add(cls);
    }
  }

  function updateEventListener() {
    if (targetEl) {
      targetEl.removeEventListener('click', clickHandler);
    }
    targetEl = el;
    if (childSelector) {
      targetEl = targetEl.querySelector(childSelector);
    }
    if (targetEl) {
      targetEl.addEventListener('click', clickHandler);
    }
  }

  function update(opts: AddClassOnClickActionOptions) {
    if (isClicked()) {
      el.classList.remove(cls);
      el.classList.add(opts.cls);
    }
    cls = opts.cls;
    childSelector = opts.childSelector;
    removeClassFromSiblings = opts.removeClassFromSiblings;
    updateEventListener();
  }

  update({ cls, childSelector, removeClassFromSiblings });

  return { update };
}

export function anchorResolveUrlAction(el: HTMLAnchorElement, linkUrl: string) {
  function update(newLinkUrl: string) {
    linkUrl = newLinkUrl;
    const resolvedUrl = resolveUrl(linkUrl);
    el.setAttribute('href', resolvedUrl.href);
    el.classList.toggle('external', resolvedUrl.external);
  }

  update(linkUrl);

  return { update };
}

export function autoFitTextAction(el: HTMLElement, enable: boolean) {
  let fittyInstance: FittyInstance = null;

  function update(newEnable: boolean) {
    if (fittyInstance) {
      fittyInstance.unsubscribe();
      fittyInstance = null;
    }
    if (newEnable) {
      fittyInstance = fitty(el);
    }
  }
  update(enable);

  return { update };
}

export function rewriteExternalCDNURL(urlString: string) {
  if (!urlString) {
    return urlString;
  }

  try {
    const url = new URL(urlString, window.location.href);
    if (CONTENTFUL_ASSETS_CDN_DOMAINS.includes(url.hostname.toLowerCase())) {
      const subdomain = url.hostname.split('.')[0]; // images, downloads, assets, videos
      url.hostname = YC_CONTENTFUL_ASSETS_CDN_DOMAIN;
      url.pathname = `/${subdomain}${url.pathname}`;
      return url.toString();
    } else if (url.hostname.toLowerCase().includes('premierleague-static-files')) {
      url.hostname = 'premierleague-static-files-cdn.yinzcam.com';
      return url.toString();
    }
  } catch (e) {
    console.warn('error while rewriting external CDN URL', e);
  }
  return urlString;
}

export function applyImageTransformation(urlString: string, transformationSlug: string) {
  if (!urlString || !transformationSlug) {
    return urlString;
  }

  try {
    const url = new URL(urlString, window.location.href);
    if (CLOUDINARY_ENABLED_DOMAINS.includes(url.hostname.toLowerCase())) {
      url.searchParams.set('clxf', `t_${transformationSlug}`);
      return url.toString();
    }
  } catch (e) {
    console.warn('error while applying image transformation to URL', e);
  }
  return urlString;
}

export function closestAspectRatio(aspectRatios: { ratio: number, name: string }[], widthPx: number, heightPx: number) {
  // Calculate the aspect ratio of the provided width and height
  const imageRatio = (1.0 * widthPx) / heightPx;

  // Find the closest aspect ratio
  let closest = aspectRatios[0];
  let smallestDifference = Math.abs(imageRatio - closest.ratio);

  for (let i = 1; i < aspectRatios.length; i++) {
    const difference = Math.abs(imageRatio - aspectRatios[i].ratio);
    if (difference < smallestDifference) {
      closest = aspectRatios[i];
      smallestDifference = difference;
    }
  }

  return closest.name;
}

export function getImageTransformationSlug(imageRole: string, containerWidthPx: number, containerHeightPx: number) {
  if (!imageRole) {
    return undefined;
  }

  if (!containerWidthPx) {
    containerWidthPx = 1;
  }
  if (!containerHeightPx) {
    containerHeightPx = 1;
  }

  switch (imageRole) {
    case 'general': {
      return `general_anysize`;
    }
    case 'general_large': {
      return `general_large`;
    }
    case 'general_small': {
      return `general_small`;
    }
    case 'general_tiny': {
      return `general_tiny`;
    }
    case 'thumbnail': {
      const aspectRatios = [
        { ratio: 16.0 / 9.0, name: 'wide' },
        { ratio: 1.0 / 1.0, name: 'square' },
        { ratio: 9.0 / 16.0, name: 'tall' },
      ];
      const closestName = closestAspectRatio(aspectRatios, containerWidthPx, containerHeightPx);
      return `thumb_face_${closestName}`;
    }
    case 'background': {
      //return undefined;
      return `background_anysize`;
    }
  }

  return undefined;
}

export function getTranslator(translations: Partial<{ language: string, [k: string]: any }>[], defaults?: { [k: string]: any }) {
  const xlmap = Object.fromEntries((translations || []).filter((t) => !_.isNil(t.language)).map((t) => [t.language, t]));
  return <T>(o: { [k: string]: T }) => {
    const entries = Object.entries(o);
    if (entries.length !== 1) {
      throw new Error('translator first argument must be an object with exactly one key');
    }
    const entry = entries[0];
    const xlation = xlmap[currentLang]?.[entry[0]];
    const defaultValue = defaults?.[entry[0]];
    const ret = ((_.isNil(xlation)) ? ((_.isString(defaultValue)) ? $t(defaultValue) : defaultValue) : xlation) as T;
    //console.log('translation', entry, defaultValue, ret);
    return ret;
  }
}

export function getComponentWrapperElementId(componentId: string) {
  return `yc-card-component:${componentId}`;
}

export function getComponentWrapperElement(componentId: string) {
  return document.getElementById(getComponentWrapperElementId(componentId));
}

export function sendMessageToComponent(componentId: string, message: Record<string, any>) {
  //console.log('send message to component', { componentId, message });
  getComponentWrapperElement(componentId).dispatchEvent(new MessageEvent('message', { data: message }));
}

export function getRenderDelayForSequenceId(sequenceId: string): number {
  return NaN;
  const layers = sequenceId.split('-');
  const lastLayer = layers[layers.length - 1];
  if (!lastLayer.startsWith('TAB') && !lastLayer.startsWith('SEC') && !lastLayer.startsWith('ARY')) {
    return NaN;
  }
  if (CONFIG.league === 'fa' && CONFIG.tricode === 'bha' && window.location.pathname.includes('/match-centre')) {
    return NaN;
  }
  let renderDelay = 0;
  let region = 0;
  let section = 0;
  for (const layer of layers) {
    const layerType = layer.slice(0, 3);
    const order = parseInt(layer.slice(3), 10);
    if (isNaN(order)) {
      continue;
    }
    let multiplier = 1;
    switch (layerType) {
      case "RGN":
        multiplier = 2000;
        region = order;
        if (region === 0) {
          return 0;
        }
        if (region === 1) {
          multiplier = 0;
        }
        break;
      case "SEC":
        multiplier = 250;
        section = order;
        if (region <= 1 && section <= 1) {
          multiplier = 0;
        }
        break;
      case "ARY":
        multiplier = 50;
        if (region <= 1 && section <= 1) {
          multiplier = 0;
        }
        break;
    }
    renderDelay += multiplier * order;
  }
  //console.log('SEQUENCE ID FOR RENDER DELAY', sequenceId, renderDelay);
  return renderDelay;
}

export interface SequentialDisplayToken {
  pageContainer: HTMLElement;
  numRegions: number;
  curRegionNum: number;
  curTabNum: number;
  curSectionNum: number;
  sectionsDisplayed: number;
  resolve: any;
  reject: any;
  next: () => void;
}

export function initSequentialDisplay(pageContainer: HTMLElement, pageData: YinzCamCardsServicePage, topLevelPage?: boolean, tokenObserver?: (tok: SequentialDisplayToken) => void): Promise<void> {
  const findComponent = (seqId: string) => {
    return pageContainer.querySelector(`.cards-component-wrapper[data-sequence-id="${seqId}"]`);
  };
  const p = promiseWithResolvers<void>();
  const tok: SequentialDisplayToken = {
    pageContainer,
    numRegions: pageData.layouts[0].region.regions.length,
    curRegionNum: 0,
    curTabNum: 0,
    curSectionNum: 0,
    sectionsDisplayed: -1,
    resolve: p.resolve,
    reject: p.reject,
    next() {
      this.sectionsDisplayed += 1;
      let regionSeqId = generateSequenceId('RGN', 'ROOT', this.curRegionNum);
      let tabSeqId = generateSequenceId('TAB', regionSeqId, this.curTabNum);
      let secSeqId = generateSequenceId('SEC', tabSeqId, this.curSectionNum);
      let sec = findComponent(secSeqId);
      if (!sec) {
        // console.log('initSequentialDisplay SECTION NOT FOUND', secSeqId);
        this.curTabNum += 1;
        this.curSectionNum = 0;
        tabSeqId = generateSequenceId('TAB', regionSeqId, this.curTabNum);
        secSeqId = generateSequenceId('SEC', tabSeqId, this.curSectionNum);
        sec = findComponent(secSeqId);
        if (!sec) {
          // console.log('initSequentialDisplay SECTION NOT FOUND', secSeqId);
          this.curRegionNum += 1;
          if (this.curRegionNum < this.numRegions) {
            this.curTabNum = 0;
            this.curSectionNum = 0;
            regionSeqId = generateSequenceId('RGN', 'ROOT', this.curRegionNum);
            tabSeqId = generateSequenceId('TAB', regionSeqId, this.curTabNum);
            secSeqId = generateSequenceId('SEC', tabSeqId, this.curSectionNum);
            sec = findComponent(secSeqId);
          }
        }
      }
      if (sec) {
        // console.log('initSequentialDisplay section found', secSeqId);
        this.curSectionNum += 1;
        sec.dispatchEvent(new CustomEvent('sequentialdisplay', { detail: this }));
        if (tokenObserver) {
          tokenObserver(this);
        }
      } else {
        // console.info('sequential display ended; sections displayed:' + this.sectionsDisplayed);
        p.resolve();
      }
    },
  };
  /*
  if (topLevelPage) {
    const waitp = promiseWithResolvers<void>();
    function waitForFirstSectionsInDOM() {
      for (let i = 0; i < tok.numRegions; i++) {
        const regionSeqId = generateSequenceId('RGN', 'ROOT', i);
        const tabSeqId = generateSequenceId('TAB', regionSeqId, 0);
        const secSeqId = generateSequenceId('SEC', tabSeqId, 0);
        const sec = findComponent(secSeqId);
        if (!sec) {
          setTimeout(waitForFirstSectionsInDOM, 100);
          return;
        }
      }
      waitp.resolve();
    }
    waitForFirstSectionsInDOM();
    waitp.promise.then(() => tok.next());
  } else {
    tok.next();
  }
  */
  tok.next();
  return p.promise;
}

export function toBooleanSafe(val?: boolean | string): boolean {
  if (_.isNil(val)) {
    return false;
  }
  if (_.isBoolean(val)) {
    return val;
  }
  if (_.isString(val)) {
    return val.toLowerCase() === 'true';
  }
  return false;
}

export function toBooleanSafeExpand(val?: boolean | string, params?: { [key: string]: string; }) {
  if (_.isString(val)) {
    val = expandTemplateParams(val, params);
  }
  return toBooleanSafe(val);
}

const formatTime = (value, singular, plural) => (value > 0 ? `${value} ${value > 1 ? plural : singular}` : "");

export function getPublishDuration(publishDateTime, t) {
  let now = DateTime.fromISO(new Date().toISOString());
  let publishDateTimeDisplay = "";
  let publishDateTimeDisplayInter = publishDateTime
    ? DateTime.fromISO(publishDateTime)
    : now;
  let [days, hours, mins, seconds] = now
    .diff(publishDateTimeDisplayInter)
    .toFormat("dd:hh:mm:ss")
    .split(":");
  let isThai = currentLang === "th";
  if (+days > 0 || !CONFIG.displayDurationForPublishDateTime) {
    publishDateTimeDisplay = `${publishDateTimeDisplayInter.toFormat(CONFIG.publishDateTimeFormat)}`;
    publishDateTimeDisplay = publishDateTimeDisplay?.split(" ")?.map((time, idx) => {
      //console.log({ idx, time });
      if (idx === 1 && t && CONFIG.dateHasComma) return `${t(time?.slice(0, 3))}${isThai ? "" : ","}`;
      else return time
    })?.join(" ");
  } else if (+hours < 0 || +mins < 0 || +days < 0) {
    publishDateTimeDisplay = t("just now");
  } else {
    publishDateTimeDisplay = `${t(formatTime(hours, t("Hour"), t("Hours")))} ${t(formatTime(mins, t("Min"), t("Mins")))} ${t('Ago')}`;
  }
  return publishDateTimeDisplay;
}

export const hideBrokenImgDisplayAltText = (event) => {
  const img = event.target;
  img.style.display = "none";
  img.style.textAlign = "center";

  const altText = document.createElement("span");
  altText.textContent = img.alt || "";
  altText.style.fontSize = "1rem";
  altText.style.fontWeight = "bold";
  altText.style.display = "inline-block";

  img.parentNode.insertBefore(altText, img);
}

export const formatDateTimeCards = (dateString, zoneLocation, dateTimeFormat) => {
  const dt = DateTime.fromISO(dateString, { zone: zoneLocation });
  const convertedDateTime = dt
      .toLocaleString(dateTimeFormat)
      ?.split(" ");
  return convertedDateTime
          ?.filter((string) => string !== "at")
          ?.map((string) => string.trim().replace(",", ""));
};