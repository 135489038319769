<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge, uniqueId } from "lodash";
import "components/cards/utilities";
import { BaseAtom, getSEOManager } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
import { JanusSEOPriority } from "js/seo";
import { onDestroy } from "svelte";
const hovering = writable(false);
export let container;
export let overlayPadding;
export let imgBackgroundImage;
export let txtFirstName;
export let txtLastName;
export let imgPlayerImage;
export let imgTshirt;
export let btnBuy;
export let btnSocialMedia;
export let showPlayerName = false;
export let setPageMetadata = undefined;
let atomWidthRem;
$: isTablet = atomWidthRem <= 80 && atomWidthRem > 70;
$: isMobile = atomWidthRem <= 70 && atomWidthRem > 45;
$: isTinyMobile = atomWidthRem <= 45;
$: backgroundImageUrl = imgBackgroundImage === null || imgBackgroundImage === void 0 ? void 0 : imgBackgroundImage.source;
$: firstNameMultiplier = isMobile || isTinyMobile ? "0.66" : "1.00";
$: lastNameMultiplier = isMobile || isTinyMobile ? "0.25" : isTablet ? "0.60" : "1.00";
$: playerImageMultiplier = isMobile || isTinyMobile ? "1.00" : isTablet ? "1.00" : "1.00";
$: tshirtImageMultiplier = isMobile || isTinyMobile ? "0.50" : isTablet ? "0.75" : "1.00";
$: btnBuyTshirtMultiplier = isMobile || isTinyMobile ? "1.00" : "1.00";
$: socialMediaMultiplier = isMobile || isTinyMobile ? "0.75" : "1.00";
const seoManager = getSEOManager();
const seoConfigKey = uniqueId();
$: if (setPageMetadata && ((txtFirstName === null || txtFirstName === void 0 ? void 0 : txtFirstName.text) || (txtLastName === null || txtLastName === void 0 ? void 0 : txtLastName.text))) {
    seoManager.registerSEOConfiguration({
        key: seoConfigKey,
        priority: JanusSEOPriority.Card,
        isPopup: false,
        title: (txtFirstName === null || txtFirstName === void 0 ? void 0 : txtFirstName.text) + ' ' + (txtLastName === null || txtLastName === void 0 ? void 0 : txtLastName.text) + CONFIG.dynamicPageTitleSuffix,
    });
}
onDestroy(() => {
    seoManager.unregisterSEOConfiguration(seoConfigKey);
});
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="PlayerHeadshotAtom"
    _cssVariablePrefix="player-headshot"
    {...container}
    {overlayPadding}
    {backgroundImageUrl}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:layout-tiny-mobile-width={isTinyMobile}
    >
        <div class="background-container">
            <ImageParticle
                _block={true}
                _hovering={$hovering}
                {...imgBackgroundImage}
            />
        </div>
        <div class="overlay-area">
            {#if showPlayerName}
                <div class="player-name">
                    <TextParticle
                        {...merge({}, txtFirstName, {
                            size: `calc(${get(txtFirstName, "size")} * ${firstNameMultiplier})`,
                        })}
                    />
                    <TextParticle
                        {...merge({}, txtLastName, {
                            size: `calc(${get(txtLastName, "size")} * ${lastNameMultiplier})`,
                        })}
                    />
                </div>
            {/if}
            <div class="player-image-container">
                <ImageParticle
                    _block={true}
                    {...merge({}, imgPlayerImage, {
                        container: {
                            width: `calc(${get(imgPlayerImage, "container.width")} * ${playerImageMultiplier})`,
                            height: `calc(${get(imgPlayerImage, "container.height")} * ${playerImageMultiplier})`,
                        },
                    })}
                />
            </div>
            <div class="social-media-tshirt">
                <div class="social-media-box" class:vertical-icons={isMobile}>
                    {#each btnSocialMedia || [] as button}
                        {#if button?.image?.source}
                            <ButtonParticle
                                {...merge({}, button, {
                                    text: {
                                        size: `calc(${get(button, "text.size")} * ${socialMediaMultiplier})`,
                                    },
                                    link: button?.URL?._text,
                                })}
                            />
                        {/if}
                    {/each}
                </div>
                <div class="tshirt-box">
                    <div class="tshirt-image">
                        <ImageParticle
                            {...merge({}, imgTshirt, {
                                container: {
                                    width: `calc(${get(imgTshirt, "container.width")} * ${tshirtImageMultiplier})`,
                                    height: `calc(${get(imgTshirt, "container.height")} * ${tshirtImageMultiplier})`,
                                },
                            })}
                        />
                        <ButtonParticle
                            {...merge({}, btnBuy, {
                                text: {
                                    size: `calc(${get(btnBuy, "text.size")} * ${btnBuyTshirtMultiplier})`,
                                },
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        background-size: cover;
        background-position: top center;
        height: 100%;
        width: 100%;
        position: relative;
    }

    .main-container > .background-container {
        height: 100%;
        width: 100%;
        position: absolute;
        overflow: hidden;
    }

    .main-container > .overlay-area {
        padding: var(--player-headshot-overlay-padding, unset);
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
    }

    .player-name {
        padding: 0rem 14.65rem;
        display: flex;
        flex-direction: column;
        line-height: "normal" !important;
    }
    .social-media-tshirt{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .tshirt-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: center;
        border: solid transparent;
        border-radius: 50%;
        z-index: 3;
    }

    .tshirt-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .social-media-box {
        display: flex;
        align-self: flex-end;
        gap: 1rem;
        z-index: 3;
    }

    .social-media-box.vertical-icons{
        flex-direction: column;
    }

    .player-image-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        bottom: 0;
        z-index: 2;
    }

    .main-container.layout-mobile-width .overlay-area, .main-container.layout-tiny-mobile-width .overlay-area {
            position: relative;
            padding: calc(var(--player-headshot-overlay-padding, 0px) * 0.5);
        }

    .main-container.layout-mobile-width .player-name, .main-container.layout-tiny-mobile-width .player-name {
            padding: 0;
        }

    .main-container.layout-mobile-width .tshirt-box, .main-container.layout-tiny-mobile-width .tshirt-box {
            z-index: 5;
            bottom: 0;
        }

    .main-container.layout-mobile-width .tshirt-image, .main-container.layout-tiny-mobile-width .tshirt-image {
            flex-direction: row;
        }

    .main-container.layout-tiny-mobile-width .tshirt-image {
            gap: 0rem;
        }

    .main-container.layout-tiny-mobile-width .tshirt-image > :global(:first-child) {
            margin-right: -1rem;
        }

    .main-container.layout-tablet-width .player-name {
            padding: 0;
        }

    .main-container.layout-tablet-width .overlay-area {
            position: relative;
        }

    .main-container.layout-tablet-width .player-image-container {
            position: absolute;
            bottom: 0;
        }

    .main-container.layout-tablet-width > .overlay-area > .social-media-box,
    .main-container.layout-mobile-width > .overlay-area > .social-media-box {
        padding: 0 1rem;
        z-index: 5;
    }</style>
