<script lang="ts">import { writable } from "svelte/store";
import { getTranslator, videoAspectRatioAction, applyImageTransformation, rewriteExternalCDNURL, } from "components/cards/utilities";
import { BaseParticle, } from "components/cards/particles";
import { onMount } from "svelte";
import StreamAMGPlayer from "./StreamAMGPlayer.svelte";
import { WebEmbedParticle } from "../WebEmbed";
import { VideoPlaybackErrorType } from "./spec";
import { createEventDispatcher } from "svelte";
const dispatch = createEventDispatcher();
// props
export let container = undefined;
export let sourceType = undefined;
export let sourceKey = "";
export let sourceUrl = "";
export let name = "";
export let poster = undefined;
export let translations = [];
export let controls = undefined;
export let autoplay = undefined;
export let muted = undefined;
export let loop = undefined;
export let playsInline = undefined;
export let aspectRatio = undefined;
export let sizing = undefined;
export let fitHorizontalAlign = undefined;
export let fitVerticalAlign = undefined;
// inputs
export let _getPlaybackToken = undefined;
// outputs
export let _sourceAspectRatio = null;
$: xlator = getTranslator(translations, {
    sourceType,
    sourceKey,
    sourceUrl,
    poster,
});
$: xlSourceType = xlator({ sourceType });
$: modifiedPosterSourceInter = rewriteExternalCDNURL(xlator({ poster }));
$: modifiedPosterSource = applyImageTransformation(modifiedPosterSourceInter, "general_anysize");
const _sourceAspectRatioStore = writable();
$: {
    _sourceAspectRatio = $_sourceAspectRatioStore;
}
let videoEl = null;
$: modifiedSourceUrlInter = rewriteExternalCDNURL(xlator({ sourceUrl }));
$: modifiedSourceUrl = new URL(modifiedSourceUrlInter, window.location.href);
$: modifiedSourceUrl.searchParams.set("clxf", "t_video_webm");
onMount(() => {
    return () => {
        if (videoEl) {
            videoEl.pause();
            videoEl.currentTime = 0;
        }
    };
});
</script>

<BaseParticle
  _name="VideoParticle"
  _cssVariablePrefix="video-particle"
  {...container}
  {aspectRatio}
  objectFit={sizing}
  {fitHorizontalAlign}
  {fitVerticalAlign}
>
  <div class="outer">
    {#if xlSourceType === "Direct Link"}
      <div class="inner">
        <video
          bind:this={videoEl}
          poster={modifiedPosterSource}
          {controls}
          {autoplay}
          muted={muted || autoplay}
          {loop}
          playsinline={playsInline}
          use:videoAspectRatioAction={_sourceAspectRatioStore}
          controlslist="nodownload"
          on:contextmenu={(e) => e.preventDefault()}
          on:canplay={() => dispatch("ready")}
          on:error={() =>
            dispatch("error", { type: VideoPlaybackErrorType.UNKNOWN_ERROR })}
        >
          <source src={modifiedSourceUrl?.toString()} type="video/webm"/>
          <track kind="captions" />
        </video>
      </div>
    {:else if xlSourceType === "Web Embed"}
      <div class="inner">
        <WebEmbedParticle {name} source={ modifiedSourceUrl?.toString()} />
      </div>
    {:else if xlSourceType === "StreamAMG"}
      <div class="inner">
        <StreamAMGPlayer
          on:ready
          on:error
          entryId={xlator({ sourceKey })}
          {autoplay}
          {muted}
          poster={modifiedPosterSource}
          getPlaybackToken={_getPlaybackToken}
        />
      </div>
    {:else}
      <div class="inner">(unknown video source)</div>
    {/if}
  </div>
</BaseParticle>

<style>
  .outer {
    width: 100%;
  }

  .inner {
    width: 100%;
  }

  video {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: var(--video-particle-object-fit, unset);
    object-position: var(--video-particle-fit-horizontal-align, unset)
      var(--video-particle-fit-vertical-align, unset);
    aspect-ratio: var(--video-particle-aspect-ratio, unset);
  }</style>
